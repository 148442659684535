import React from "react";
import { Route, Switch } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Sidebar from "./Sidebar";
import { TapirAdmin } from "./tapir/TapirAdmin";
import { CollarAdmin } from "./collar/CollarAdmin";
import { ZonaAdmin } from "./zona/ZonaAdmin";
import UsuariosAdmin from "./usuarios/UsuariosAdmin";

export const AdminHome = ({ match }) => {
  return (
    <>
      <Col
        xs={6}
        md={3}
        className="px-0"
        style={{ backgroundColor: "#E3E4E4" }}
      >
        <Switch>
          <Route exact path="/admin" component={() => <Sidebar />} />
          <Route
            exact
            path={match.path + "/tapires"}
            component={() => <Sidebar activeKey={"1"} />}
          />
          <Route
            exact
            path={match.path + "/collares"}
            component={() => <Sidebar activeKey={"2"} />}
          />
          <Route
            exact
            path={match.path + "/zonas"}
            component={() => <Sidebar activeKey={"3"} />}
          />
          <Route
            exact
            path={match.path + "/usuarios"}
            component={() => <Sidebar activeKey={"4"} />}
          />
        </Switch>
      </Col>
      <Col xs={12} md={9} className="">
        <Switch>
          <Route exact path={match.path + "/tapires"} component={TapirAdmin} />
          <Route
            exact
            path={match.path + "/collares"}
            component={CollarAdmin}
          />
          <Route path={match.path + "/zonas"} component={ZonaAdmin} />
          <Route
            exact
            path={match.path + "/usuarios"}
            component={UsuariosAdmin}
          />
          <Route
            exact
            path={""}
            component={() => (
              <div
                className="d-flex justify-content-center align-items-center flex-column"
                style={{ width: "100%", height: "100vh" }}
              >
                <div className="font-weight-bold text-secondary">
                  Nada seleccionado
                </div>
                <div className="text-secondary">
                  Seleccionar una opción del menú de la izquierda.
                </div>
              </div>
            )}
          />
        </Switch>
      </Col>
    </>
  );
};

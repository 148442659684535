import React from "react";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import { Formik } from "formik";
import { PropTypes } from "prop-types";
import { UserContext } from "../../UserContext";

export class ZonaForm extends React.Component {
  static contextType = UserContext;

  validate = (values) => {
    const errors = {};
    if (!values.NombreZona) {
      errors.NombreZona = "Campo requerido";
    }
    if (values.EsCobertura === null && values.EsCobertura === undefined) {
      errors.EsCobertura = "Campo requerido";
    }
    return errors;
  };

  render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        validate={this.validate}
        onSubmit={this.props.onSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                id="NombreZona"
                name="NombreZona"
                {...formik.getFieldProps("NombreZona")}
                isInvalid={
                  formik.errors.NombreZona && formik.touched.NombreZona
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.NombreZona}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              style={{
                display:
                  this.context.user !== null &&
                  this.context.user.groups !== undefined &&
                  this.context.user.groups[0] !== undefined
                    ? "none"
                    : "",
              }}
            >
              <Form.Check
                type="checkbox"
                id="EsCobertura"
                name="EsCobertura"
                label="Es zona de cubertura"
                defaultChecked={formik.values.EsCobertura}
                {...formik.getFieldProps("EsCobertura")}
                isInvalid={formik.errors.EsCobertura}
              />
            </Form.Group>
            <ButtonGroup>
              <Button className="mr-2" type="submit">
                Guardar
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

ZonaForm.defaultProps = {
  initialValues: {
    NombreZona: "",
    EsCobertura: false,
  },
  onSubmit: () => {},
  onCancel: () => {},
};

ZonaForm.propTypes = {
  initialValues: PropTypes.shape({
    NombreZona: PropTypes.string,
    EsCobertura: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

import axios from "axios";
import { getSquare, api_root } from "../../utils";

export class ZonaManager {
  static all = (token, thenFunction) => {
    axios
      .get(`${api_root}ListZona_ZonasDelimitadas/`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then(res => thenFunction(res))
      .catch(err => console.error(err));
  };

  static delete = (token, zona, thenFunction) => {
    axios
      .delete(`${api_root}ListZona_ZonasDelimitadas/${zona.id}/`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then(res => thenFunction(res))
      .catch(err => console.error(err));
  };

  static editOrCreate = (token, zona, thenFunction) => {
    const url = `${api_root}ZonasDelimitadas/${zona.id ? zona.id + "/" : ""}`;
    const method = `${zona.id ? "put" : "post"}`;
    const data = {
      NombreZona: zona.NombreZona,
      EsCobertura: zona.EsCobertura
    };
    axios
      .request({
        url,
        method,
        data,
        headers: { Authorization: `Token ${token}` }
      })
      .then(res => thenFunction(res))
      .catch(err => console.error(err));
  };

  static deletePuntosZona = (token, zonaId, thenFunction) => {
    axios
      .delete(`${api_root}PuntosZona/?zona=${zonaId}`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then(res => thenFunction(res))
      .catch(err => console.error(err));
  };

  static deletePuntosZonaCuadrada = (token, zonaId, thenFunction) => {
    axios
      .delete(`${api_root}PuntosZonaCuadrada/?zona=${zonaId}`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then(res => thenFunction(res))
      .catch(err => console.error(err));
  };

  static addPuntosZona = (token, puntos, thenFunction) => {
    const url = `${api_root}PuntosZona/`;
    const data = [
      ...puntos.marcadores.map(punto => {
        return {
          ZonaDelimitada: puntos.ZonaDelimitada,
          Latitud: punto.lat,
          Longitud: punto.lng
        };
      })
    ];
    axios
      .post(url, data, { headers: { Authorization: `Token ${token}` } })
      .then(() => thenFunction())
      .catch(err => console.error(err));
    this.addPuntosZonaCuadrada(token, puntos.marcadores, puntos.ZonaDelimitada);
  };

  static addPuntosZonaCuadrada = (token, puntos, zonaDelimitada) => {
    const url = `${api_root}PuntosZonaCuadrada/`;
    const cuadrados = getSquare(puntos);
    let data = [
      ...cuadrados.map(punto => {
        return {
          ZonaDelimitada: zonaDelimitada,
          Latitud: punto.lat,
          Longitud: punto.lng
        };
      })
    ];
    axios
      .post(url, data, { headers: { Authorization: `Token ${token}` } })
      .then(() => {})
      .catch(err => console.error(err));
  };

  static addTapirZona = (token, tapir, zona, problematicos, thenFunction) => {
    const esProblematico = problematicos.filter(
      p =>
        Number(p.Tapir) === Number(tapir) &&
        Number(p.ZonaDelimitada) === Number(zona)
    )[0];
    const url = `${api_root}ZonaTapir/${
      esProblematico ? esProblematico.id + "/" : ""
    }`;
    const method = `${esProblematico ? "put" : "post"}`;
    const data = {
      FechaInicio: new Date().toISOString().slice(0, 10),
      FechaFin: null,
      Tapir: Number(tapir),
      ZonaDelimitada: zona
    };
    axios
      .request({
        url,
        method,
        data,
        headers: { Authorization: `Token ${token}` }
      })
      .then(() => thenFunction())
      .catch(err => console.error(err));
  };

  static removeTapirZona = (token, tapirId, tapireszona, thenFunction) => {
    const tapirzona = tapireszona.find(
      item => Number(item.Tapir.id) === Number(tapirId)
    );
    const url = `${api_root}ZonaTapir/${tapirzona.id}/`;
    const data = {
      FechaInicio: tapirzona.FechaInicio,
      FechaFin: new Date().toISOString().slice(0, 10),
      Tapir: tapirzona.Tapir.id,
      ZonaDelimitada: tapirzona.ZonaDelimitada
    };
    axios
      .put(url, data, { headers: { Authorization: `Token ${token}` } })
      .then(res => thenFunction(res))
      .catch(err => console.error(err));
  };

  static tapiresLibres = (token, thenFunction) => {
    axios
      .get(`${api_root}Tapires/?soloLibres=true`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then(res => thenFunction(res))
      .catch(err => console.error(err));
  };

  static tapiresProblematicos = (token, thenFunction) => {
    axios
      .get(`${api_root}ZonaTapir/?soloProblematicos=true`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then(res => thenFunction(res))
      .catch(err => console.error(err));
  };
}

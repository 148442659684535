import React from "react";
import { PropTypes } from "prop-types";
import { IconButton } from "./IconButton";

export const TitleAdmin = props => (
  <h1>
    {props.title + " "}
    <IconButton
      tooltip={props.tooltip}
      name="crearEditar"
      icon="plus-circle"
      color="limegreen"
      onClick={event => {
        props.onCrear();
        props.showModal(event);
      }}
    />
  </h1>
);

TitleAdmin.propTypes = {
  title: PropTypes.string,
  onCrear: PropTypes.func,
  showModal: PropTypes.func
};

import React from "react";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PageFooter() {
  return (
    <>
      <Col xs="12" md="6">
        <p>
          Redes Sociales del Proyecto Tapir <br />
          <a href="https://www.facebook.com/TapirTucumano/">
            <FontAwesomeIcon
              icon={["fab", "facebook"]}
              size="lg"
              className="mr-3"
            />
          </a>
          <a href="https://www.instagram.com/proyectotapir/">
            <FontAwesomeIcon
              icon={["fab", "instagram"]}
              size="lg"
              className="mr-3"
            />
          </a>
        </p>
        <p style={{ fontSize: "0.6rem" }}>
          Favicon from <a href="https://www.iconshock.com/">Iconshock</a>
          <br />
          Iconos del mapa diseñados por{" "}
          <a href="https://www.flaticon.es/autores/freepik" title="Freepik">
            Freepik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.es/" title="Flaticon">
            {" "}
            www.flaticon.es
          </a>
        </p>
      </Col>
      <Col className="text-right">
        <p>
          Facultad de Ciencias Exactas y Tecnologías
          <br />
          Facultad de Ciencias Naturales e I.M.L. <br />
          Universidad Nacional de Tucumán
        </p>
      </Col>
    </>
  );
}

import React from "react";
import { Button, Col, Form, Container, Modal } from "react-bootstrap";
import { api_root } from "./utils";
import { Formik } from "formik";
import axios from "axios";
import { Redirect } from "react-router-dom";

export class ChangePasswordForm extends React.Component {
  state = {
    initialValues: {
      oldPassword: "",
      newPassword1: "",
      newPassword2: ""
    },
    token: this.props.token,
    success: false,
    showModalSuccess: false,
    showModalError: false,
    msjErr: "",
    msjSuccess: ""
  };

  validate = values => {
    const errors = {};
   /* if (values.newPassword1.length >= 4) {
      errors.newPassword1 = false;
    }
    if (values.newPassword2.length >= 4) {
      errors.newPassword2 = false;
    }*/
    if (!values.oldPassword) {
      errors.oldPassword = "Campo requerido";
    }
    if (!values.newPassword1) {
      errors.newPassword1 = "Campo requerido";
    }
    if (values.newPassword1.length < 4) {
      errors.newPassword1 = "Contraseña muy corta";
    }
    if (!values.newPassword2) {
      errors.newPassword2 = "Campo requerido";
    }
    if (values.newPassword2.length < 4) {
      errors.newPassword2 = "Contraseña muy corta";
    }
    return errors;
  };

  handleError = err => {
    switch (err) {
      case 400:
        this.setState({ msjErr: "Error en la contraseña" });
        break;
      case 500:
        this.setState({ msjErr: "Problemas en la red, pruebe mas tarde" });
        break;
      default:
        this.setState({ msjErr: "Problemas en la red, comuniquese con el admin" });
        break;
    }
  };

  modalSuccess = () => {
    const msj = this.state.msjSuccess;
    return (
      <Modal.Dialog>
        <Modal.Header closeButton onClose={this.hideModalSuccess}>
          <Modal.Title>EXITO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{msj}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.hideModalSuccess}>
            Close
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    );
  };

  hideModalSuccess = () => {
    this.setState({ showModalSuccess: false, success: true });
  };

  modalError = () => {
    const msj = this.state.msjErr;
    return (
      <Modal.Dialog>
        <Modal.Header closeButton onClose={this.hideModalError}>
          <Modal.Title>ERROR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{msj}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.hideModalError}>
            Close
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    );
  };

  hideModalError = () => {
    this.setState({ showModalError: false });
  };


  handleOnSubmit = values => {
    this.sendNewPassword(values);
  };

  sendNewPassword = values => {
    const token = this.state.token;
    const data = {
      new_password1: values.newPassword1,
      new_password2: values.newPassword2,
      old_password: values.oldPassword
    };

    var formData = new FormData();

    // Push our data into our FormData object
    for (var name in data) {
      formData.append(name, data[name]);
    }

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      }
    };

    axios.post(`${api_root}change-pass/`, formData, headers)
    .then(res => {
        this.setState({ showModalSuccess: true });
        this.setState({ msjSuccess: "Cambio de contraseña exitoso" });
      })
    .catch(err => {
      this.setState({ showModalError: true });
      this.handleError(err.response.status);
    });
  };

  render() {
    if (this.state.success) {
      return <Redirect to="/perfil" />;
    }
    if (this.state.showModalError) {
      return this.modalError();
    }
    if (this.state.showModalSuccess) {
      return this.modalSuccess();
    }
    return (
      <Container align="center" className="my-3">
        <Col
          md={6}
          className="justify-content-center"
          style={{
            borderStyle: "solid",
            borderWidth: "2px",
            borderColor: "grey"
          }}
        >
          <div className="d-flex justify-content-center my-3">
            <h3>CAMBIAR CONTRASEÑA</h3>
          </div>
          <Formik
            initialValues={this.state.initialValues}
            validate={this.validate}
            onSubmit={this.handleOnSubmit}
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
                  <Form.Label className="d-flex justify-content-start">
                    Contraseña actual:{" "}
                  </Form.Label>
                  <Form.Control
                    id="oldPassword"
                    type="password"
                    name="oldPassword"
                    {...formik.getFieldProps("oldPassword")}
                    isInvalid={
                      formik.errors.oldPassword && formik.touched.oldPassword
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.oldPassword}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="d-flex justify-content-start">
                    Nueva contraseña:{" "}
                  </Form.Label>
                  <Form.Control
                    id="newPassword1"
                    type="password"
                    name="newPassword1"
                    {...formik.getFieldProps("newPassword1")}
                    isInvalid={
                      formik.errors.newPassword1 && formik.touched.newPassword1
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.newPassword1}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="d-flex justify-content-start">
                    Repetir nueva contraseña:{" "}
                  </Form.Label>
                  <Form.Control
                    id="newPassword2"
                    type="password"
                    name="newPassword2"
                    {...formik.getFieldProps("newPassword2")}
                    isInvalid={
                      formik.errors.newPassword2 && formik.touched.newPassword2
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.newPassword2}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button className="my-3" variant="primary" type="submit">
                  Guardar
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Container>
    );
  }
}

import React from "react";
import { PropTypes } from "prop-types";
import { Formik } from "formik";
import { ButtonGroup, Button, Form } from "react-bootstrap";

/** Este componente renderiza un form con un menú desplegable. */
export default function SelectForm(props) {
  function validate(values) {
    const errors = {};
    if (!values.elegido) {
      errors.elegido = "Campo requerido";
    }
    return errors;
  }

  return (
    <Formik
      initialValues={props.initialValues}
      validate={validate}
      onSubmit={props.onSubmit}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Control
              as="select"
              id="elegido"
              name="elegido"
              {...formik.getFieldProps("elegido")}
              isInvalid={formik.errors.elegido && formik.touched.elegido}
            >
              <option value={"0"}>Elegir opción</option>
              {props.options}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {formik.errors.elegido}
            </Form.Control.Feedback>
          </Form.Group>
          <ButtonGroup>
            <Button className="mr-2" type="submit">
              Guardar
            </Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
}

SelectForm.propTypes = {
  /** Función que se ejecuta al hacer click en el botón "Guardar" */
  onSubmit: PropTypes.func,
  /** Las opciones del menú desplegable */
  options: PropTypes.array,
  /** Debe contener el campo elegido inicializado.
   * Se recomienda elegido: "0"
   */
  initialValues: PropTypes.object,
};

import React from "react";
import { Navbar, Nav, NavDropdown, Col } from "react-bootstrap";
import { UserContext } from "./UserContext";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { logout } from "./auth";

export class Navbarcomp extends React.Component {
  static contextType = UserContext;

  render() {
    const { isAuthenticated, user } = this.context;

    const authLinks = (
      <>
        <Nav className="mr-auto">
          <Nav.Link
            to="../alarmas"
            as={Link}
            style={{
              display:
                user !== null &&
                user.groups !== undefined &&
                user.groups[0] !== undefined &&
                user.groups[0].Nombre === "patrocinador"
                  ? "none"
                  : "",
            }}
          >
            Alarmas
          </Nav.Link>
          <Nav.Link
            to="../admin"
            as={Link}
            style={{
              display:
                user !== null &&
                user.groups !== undefined &&
                user.groups[0] !== undefined &&
                (user.groups[0].Nombre === "restringido" ||
                  user.groups[0].Nombre === "patrocinador")
                  ? "none"
                  : "",
            }}
          >
            Admin
          </Nav.Link>
        </Nav>
        <Nav>
          <NavDropdown
            alignRight
            title={
              this.props.data.user ? this.props.data.user.username : "usuario"
            }
            className="mr-auto"
          >
            <NavDropdown.Item to="../perfil" as={Link}>
              Ver perfil
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => logout(this.context.setValues)}>
              Cerrar sesión
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </>
    );

    //definido por si hace falta mas adelante
    const guestLinks = "";

    return (
      <Col className="px-0">
        <Navbar variant="dark" bg="dark" expand="lg">
          <Navbar.Brand to="/" as={Link}>
            Trackpir
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            {isAuthenticated ? authLinks : guestLinks}
          </Navbar.Collapse>
        </Navbar>
      </Col>
    );
  }
}

Navbarcomp.propTypes = {
  data: PropTypes.object,
};

import React from "react";
import { Col } from "react-bootstrap";
import { ZonaForm } from "./ZonaForm";
import { ZonaTable } from "./ZonaTable";
import { ZonaManager } from "./ZonaManager";
import { PuntosInput } from "./PuntosInput";
import { SelectForm } from "../SelectForm";
import { GenericModal } from "../../usuario/GenericModal";
import { TitleAdmin } from "../TitleAdmin";
import { UserContext } from "../../UserContext";

export class ZonaAdmin extends React.Component {
  _isMounted = false;
  static contextType = UserContext;
  state = {
    zonas: [],
    selectedZona: null,
    tapiresLibres: [],
    tapiresProblematicos: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.refreshData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Para el manejo de los datos.
  setZonas = res => {
    this._isMounted && this.setState({ zonas: res.data });
  };

  // Para saber cual es la zona que quiero editar o borrar.
  setSelectedZona = zona => {
    this._isMounted && this.setState({ selectedZona: zona });
  };

  setTapiresLibres = res => {
    this._isMounted && this.setState({ tapiresLibres: res.data });
  };

  setTapiresProblematicos = res => {
    this._isMounted && this.setState({ tapiresProblematicos: res.data });
  };

  refreshData = () => {
    ZonaManager.all(this.context.token, this.setZonas);
    ZonaManager.tapiresLibres(this.context.token, this.setTapiresLibres);
    ZonaManager.tapiresProblematicos(
      this.context.token,
      this.setTapiresProblematicos
    );
  };

  render() {
    const tableProps = {
      values: this.state.zonas,
      setSelected: this.setSelectedZona
    };

    const modal = {
      title: {
        crearEditar: "Zona",
        agregarPuntos: "Puntos de la zona",
        agregarTapires: "Tapires para la zona",
        borrarTapires: "Tapires de la zona",
        borrarZona: "Confirmación",
        borrarPuntos: "Confirmacion"
      },
      bodyObj: {
        crearEditar: props => (
          <ZonaForm
            initialValues={this.state.selectedZona}
            onSubmit={zona => {
              ZonaManager.editOrCreate(
                this.context.token,
                zona,
                // thenFunction
                () => ZonaManager.all(this.context.token, this.setZonas)
              );
              props.hideModal();
            }}
          />
        ),
        agregarPuntos: props => (
          <PuntosInput
            initialValues={this.state.selectedZona}
            onSubmit={puntos => {
              ZonaManager.addPuntosZona(
                this.context.token,
                puntos,
                //thenFunction
                () => ZonaManager.all(this.context.token, this.setZonas)
              );
              props.hideModal();
            }}
          />
        ),
        agregarTapires: props => (
          <SelectForm
            options={this.state.tapiresLibres}
            onSubmit={value => {
              ZonaManager.addTapirZona(
                this.context.token,
                value.elegido,
                this.state.selectedZona.id,
                this.state.tapiresProblematicos,
                //thenFunction
                this.refreshData
              );
              props.hideModal();
            }}
          />
        ),
        borrarTapires: props => (
          <SelectForm
            options={
              this.state.selectedZona
                ? this.state.selectedZona.zonatapir_set.map(item => item.Tapir)
                : []
            }
            onSubmit={value => {
              ZonaManager.removeTapirZona(
                this.context.token,
                value.elegido,
                this.state.selectedZona.zonatapir_set,
                //thenFunction
                this.refreshData
              );
              props.hideModal();
            }}
          />
        ),
        borrarZona: props => <p>{"¿Está seguro que desea borrar la zona?"}</p>,
        borrarPuntos: props => (
          <p>{"¿Está seguro que desea borrar los puntos de la zona?"}</p>
        )
      },
      renderFooter: {
        borrarZona: true,
        borrarPuntos: true
      },
      yesAction: {
        borrarZona: () =>
          ZonaManager.delete(
            this.context.token,
            this.state.selectedZona,
            //thenFunction
            () => ZonaManager.all(this.context.token, this.setZonas)
          ),
        borrarPuntos: () =>
          ZonaManager.deletePuntosZona(
            this.context.token,
            this.state.selectedZona.id,
            //thenFunction
            () =>
              ZonaManager.deletePuntosZonaCuadrada(
                this.context.token,
                this.state.selectedZona.id,
                () => ZonaManager.all(this.context.token, this.setZonas)
              )
          )
      },
      size: {
        agregarPuntos: "xl"
      }
    };

    return (
      <Col>
        <GenericModal {...modal}>
          <TitleAdmin
            tooltip="Nueva zona"
            title="Zonas"
            onCrear={() => this.setSelectedZona(undefined)}
          />
          <ZonaTable {...tableProps} />
        </GenericModal>
      </Col>
    );
  }
}

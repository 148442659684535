import React from "react";
import { Table } from "react-bootstrap";
import { IconButton } from "../IconButton";
import PropTypes from "prop-types";

export class CollarTable extends React.Component {
  render() {
    const rows = this.props.values.map((collar) => {
      const onClick = (event) => {
        event.preventDefault();
        this.props.setSelected(collar);
        this.props.showModal(event);
      };
      return (
        <tr key={collar.id}>
          <td>{collar.Nombre}</td>
          <td>{collar.MACAddress}</td>
          <td className="text-break">{collar.Observacion}</td>
          <td>
            <IconButton
              tooltip="Editar"
              name="crearEditar"
              icon="edit"
              onClick={onClick}
            />
            <IconButton
              tooltip="Borrar"
              name="borrar"
              icon="trash-alt"
              onClick={onClick}
            />
          </td>
        </tr>
      );
    });

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Dirección MAC</th>
            <th style={{ width: "400px" }}>Observaciones</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }
}

CollarTable.propTypes = {
  values: PropTypes.array,
  setSelected: PropTypes.func,
  showModal: PropTypes.func,
};

CollarTable.defaultProps = {
  //values: cada elemento del arreglo es un collar.
  values: [
    {
      id: "defaultId",
      Nombre: "",
      MACAddress: "",
      Observacion: "",
    },
  ],
  setSelected: () => {},
  showModal: () => {},
};

import React from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { CollarForm } from "./CollarForm";
import { CollarTable } from "./CollarTable";
import { CollarManager } from "./CollarManager";
import { GenericModal } from "../../usuario/GenericModal";
import { TitleAdmin } from "../TitleAdmin";
import { UserContext } from "../../UserContext";

export class CollarAdmin extends React.Component {
  static contextType = UserContext;
  state = {
    collares: [],
    selectedCollar: null,
    showError: false,
  };

  componentDidMount() {
    CollarManager.all(this.context.token, this.setCollares);
  }

  errorBorrarCollar = (err) => {
    if (err.response.status === 405) {
      this.setState({ showError: true });
    }
    console.error(err);
  };

  setCollares = (res) => {
    this.setState({ collares: res.data });
  };

  // Para saber cual es el collar que quiero editar o borrar.
  setSelectedCollar = (collar) => {
    this.setState({ selectedCollar: collar });
  };

  render() {
    const tableProps = {
      values: this.state.collares,
      setSelected: this.setSelectedCollar,
    };
    const modal = {
      title: {
        crearEditar: "Collar",
        borrar: "Confirmación",
      },
      bodyObj: {
        crearEditar: (props) => (
          <CollarForm
            initialValues={this.state.selectedCollar}
            onSubmit={(collar) => {
              CollarManager.editOrCreate(
                this.context.token,
                collar,
                // thenFunction
                () => CollarManager.all(this.context.token, this.setCollares)
              );
              props.hideModal();
            }}
          />
        ),
        borrar: () => <p>{"¿Está seguro que desea borrar el collar?"}</p>,
      },
      renderFooter: {
        borrar: true,
      },
      yesAction: {
        borrar: () =>
          CollarManager.delete(
            this.context.token,
            this.state.selectedCollar,
            //thenFunction
            () => CollarManager.all(this.context.token, this.setCollares),
            //catchFunction
            this.errorBorrarCollar
          ),
      },
    };
    return (
      <Col>
        <GenericModal {...modal}>
          <TitleAdmin
            title="Collares"
            tooltip={"Nuevo collar"}
            onCrear={() => this.setSelectedCollar(undefined)}
          />
          <CollarTable {...tableProps} />
        </GenericModal>
        <Modal
          show={this.state.showError}
          onHide={() => this.setState({ showError: false })}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              "No se puede borrar el collar. Es posible que tenga mediciones asociadas."
            }
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showError: false })}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    );
  }
}

import React from "react";
import { PropTypes } from "prop-types";
import SelectForm from "../../../commons/SelectForm";

/** Este componente renderiza un formulario para agregar un Tapir
 * a la configuración de alertas de un usuario.
 */
export default function AgregarTapirForm(props) {
  /** Acomoda los datos que serán enviados en la request */
  function arrengeData(values) {
    const dataToSend = props.data.tipos.map((item, i) => {
      return {
        User: props.initialValues.User,
        TipoAlerta: item.id,
        FechaDesde: new Date().toISOString().slice(0, 10),
        FechaHasta: null,
        Tapir: values.elegido,
      };
    });
    return dataToSend;
  }

  /** Función que se ejecta al hacer click
   * en el botón "Guardar" (o equivalente) del Form.
   */
  function onSubmit(values) {
    props.onSubmit(arrengeData(values));
  }

  const { tapires } = props.data;

  // Opciones para llenar el menú desplegable (SelectForm).
  const options = tapires.map((option) => {
    return (
      <option key={option.id} value={option.id}>
        {option.Nombre}
      </option>
    );
  });

  return (
    <SelectForm
      options={options}
      initialValues={props.initialValues}
      onSubmit={onSubmit}
    />
  );
}

AgregarTapirForm.propTypes = {
  /** Las opciones que se mostrarán en el menú desplegable.*/
  data: PropTypes.object,

  /** Requerido por Formik */
  initialValues: PropTypes.shape({
    /* Elegido por defecto en el menú desplegable. Valor recomendado: "0" */
    elegido: PropTypes.string,
    /* user al que se agregará el tipo */
    User: PropTypes.number,
  }),

  /** Función que realiza el POST o PUT con los datos.*/
  onSubmit: PropTypes.func,
};

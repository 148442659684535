import React from "react";
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  CircleMarker,
  Pane,
  LayersControl,
} from "react-leaflet";
import { getColor, map_center, getColorIconos } from "../utils";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import L from "leaflet";
import "./leaflet.css";

const lugares = {
  HorcoMolle: {
    latLng: { lat: -26.7929563, lng: -65.3164028 },
    nombre: "Reserva Horco Molle",
  },
  EscuelaAgricultura: {
    latLng: { lat: -26.77972, lng: -65.32449 },
    nombre: "Escuela de Agricultura y Sacarotécnia - UNT",
  },
};

class LeafletMap extends React.Component {
  //map = React.createRef();
  componentDidMount() {
    //this.setState({marcadores: this.props.marcadores})
    const map = this.refs.mapRef.leafletElement;
    //const map = this.map.current.leafletElement;
    setTimeout(() => {
      map.invalidateSize(false);
    }, 300);
  }

  /*
   * Este método se define para dibujar un Polygon en el mapa
   * Argumentos:
   *   puntos: un arreglo con los puntos del mapa que serán vértices del Polygon.
   *   index: un número que identifique unívocamente al arreglo "puntos".
   */
  drawPolygon = (puntos, index, dashArray) => {
    //controlo que 'puntos' esté definido, que sea un arreglo y que no esté vacío
    if (
      puntos.puntos &&
      puntos.puntos.constructor === Array &&
      puntos.puntos.length !== 0
    ) {
      return (
        <Polygon
          key={index}
          positions={puntos.puntos}
          color={getColor(puntos.id)}
          zIndex={1}
          dashArray={dashArray ? dashArray : ""}
          weight={0.5}
        />
      );
    } else {
      return null;
    }
  };

  /*
   * Este método se define para dibujar múltiples Polygones en el mapa-
   * Argumentos:
   *   polygones: un arreglo con arreglos que contienen puntos del mapa que serán vertices de los Polygones.
   */
  drawPolygones = (polygones, dashArray) => {
    //controlo que 'polygones' esté definido, que sea un arreglo y que no esté vacío
    if (
      polygones &&
      polygones.constructor === Array &&
      polygones.length !== 0
    ) {
      return polygones.map((puntos, index) => {
        return this.drawPolygon(puntos, index, dashArray);
      });
    } else {
      return null;
    }
  };

  /*
  * marcadores: arreglo de objetos de la siguiente forma
    {
      latLng: {lat: number, lng: number}
      nombre: string
    }
  */
  drawMarkers = (marcadores) => {
    if (
      marcadores &&
      marcadores.constructor === Array &&
      marcadores.length !== 0
    ) {
      return marcadores.map((marcador, index) => {
        return (
          <Marker key={index} position={marcador.latLng}>
            <Popup>{marcador.nombre}</Popup>
          </Marker>
        );
      });
    } else {
      return null;
    }
  };

  /*
  * marcadores: arreglo de objetos de la siguiente forma
    {
      latLng: {lat: number, lng: number}
      nombre: string
    }
  */
  drawCircleMarkers = (marcadores, i) => {
    if (
      marcadores &&
      marcadores.constructor === Array &&
      marcadores.length !== 0
    ) {
      const len = marcadores.length;
      const opacity = 1 / len;
      return marcadores.map((marcador, index) => {
        return (
          <CircleMarker
            key={index}
            center={marcador.latLng}
            fillOpacity={opacity * (index + 1)}
            radius={6}
            stroke={false}
            fillColor={getColor(marcador.nombre.id)}
            zIndexOffset={-1000}
          >
            <Popup>
              <h6>Tapir {marcador.nombre.tapir.Nombre}</h6>
              <h6>{marcador.nombre.fecha}</h6>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  this.props.detalle(marcador.nombre);
                }}
              >
                Ver detalle
              </Button>
            </Popup>
          </CircleMarker>
        );
      });
    } else {
      return null;
    }
  };

  drawCustomizableMarkers = (marcadores, i) => {
    if (
      marcadores &&
      marcadores.constructor === Array &&
      marcadores.length !== 0
    ) {
      const len = marcadores.length;
      return marcadores.map((marcador, index) => {
        var color = getColorIconos(marcador.nombre.id);
        let pruebaIcon = L.icon({
          iconUrl: require(`./images/tapir-${color}.png`),
          iconSize: [30, 30],
          //shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        });
        return (
          <Marker
            key={index}
            position={marcador.latLng}
            opacity={(len - index)/len}
            icon={pruebaIcon}
          >
            <Popup>
              <h6>Tapir {marcador.nombre.tapir.Nombre}</h6>
              <h6>{marcador.nombre.fecha}</h6>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => {
                  this.props.detalle(marcador.nombre);
                }}
              >
                Ver detalle
              </Button>
            </Popup>
          </Marker>
        );
      });
    } else {
      return null;
    }
  };

  /*
   *
   */
  drawArrayCircleMarkers = (arreglo) => {
    if (arreglo && arreglo.constructor === Array && arreglo.length !== 0) {
      return arreglo.map((element, index) => {
        return this.drawCircleMarkers(element, index);
        //return this.drawCustomizableMarkers(element, index);
      });
    } else {
      return null;
    }
  };

  drawArrayCustomizableMarkers = (arreglo) => {
    if (arreglo && arreglo.constructor === Array && arreglo.length !== 0) {
      return arreglo.map((element, index) => {
        return this.drawCustomizableMarkers(element, index);
      });
    } else {
      return null;
    }
  };

  render() {

    let escuelaIcon = L.icon({
      iconUrl: require(`./images/escuela.png`),
      iconSize: [40, 40],
    });
    let reservaIcon = L.icon({
      iconUrl: require(`./images/reserva.png`),
      iconSize: [40, 40],
    });

    return (
      <>
        <Map
          center={map_center}
          zoom={13}
          style={{ width: "100%", height: "500px" }}
          ref="mapRef"
          //ref={this.map}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="Mapa" checked={true}>
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satélite Esri">
              <TileLayer
                attribution="Tiles &copy; Esri &mdash; Source: Esri, DigitalGlobe, Earthstar Geographics, CNES/Airbus DS, GeoEye, USDA FSA, USGS, Aerogrid, IGN, IGP, and the GIS User Community"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          <Marker
            position={lugares.HorcoMolle.latLng}
            icon={reservaIcon}
            //opacity="0.2"
          >
            <Popup>{lugares.HorcoMolle.nombre}</Popup>
          </Marker>
          <Marker
            position={lugares.EscuelaAgricultura.latLng}
            icon={escuelaIcon}
          >
            <Popup>{lugares.EscuelaAgricultura.nombre}</Popup>
          </Marker>
          <Pane style={{ zIndex: 499 }}>
            {this.drawPolygones(this.props.zonas)}
          </Pane>
          <Pane style={{ zIndex: 499 }}>
            {this.drawPolygones(this.props.cuadrados, "4 8")}
          </Pane>
          <Pane style={{ zIndex: 500 }}>
            {
              //this.drawArrayCircleMarkers(this.props.posiciones)
              this.drawArrayCustomizableMarkers(this.props.posiciones)
            }
          </Pane>
        </Map>
      </>
    );
  }
}

LeafletMap.propTypes = {
  detalle: PropTypes.func,
  zonas: PropTypes.array,
  cuadrados: PropTypes.array,
  posiciones: PropTypes.array,
};

export default LeafletMap;

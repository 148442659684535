import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { IconButton } from "../admin/IconButton";
import UniversalModal from "../commons/UniversalModal";
import ConfirmationForm from "../commons/ConfirmationForm";
import ConfigTapiresTabla from "./configTapires/ConfigTapiresTabla";
import EliminarTipoForm from "./configTapires/configTapiresForms/EliminarTipoForm";
import AgregarTipoForm from "./configTapires/configTapiresForms/AgregarTipoForm";
import AgregarTapirForm from "./configTapires/configTapiresForms/AgregarTapirForm";
import useSubmit from "../commons/useSubmit";
import multipleSubmitProveedor from "../commons/multipleSubmitProveedor";
import useDataProveedor from "../commons/useDataProveedor";
import useUniversalModal from "../commons/useUniversalModal";

/** Este componente renderiza el layout para la sección
 * de tapires de la configuración de alertas de un usuario.
 */
export default function ConfigTapires(props) {
  // Contiene el tapir seleccionado, es decir, el tapir sobre el que se hizo clic
  //alguno de los botones de la columna "Acciones".
  const [selected, setSelected] = useState(null);

  /** Para manejar el componente UniversalModal */
  const [show, showModal, hideModal] = useUniversalModal();

  // Hook para ELIMINAR UNA ALERTA.
  const [statusEliminar, onSubmitEliminar] = useSubmit({
    urlSubmit: "AlertasUsuarios/",
    thenFunction: () => {
      props.refreshData();
      refreshTapires();
      refreshTiposAgregar();
    },
  });

  // Hook para AGREGAR UN TIPO.
  const [statusAgregarTipo, onSubmitAgregarTipo] = useSubmit({
    urlSubmit: "AlertasUsuarios/",
    thenFunction: () => {
      props.refreshData();
      refreshTapires();
      refreshTiposAgregar();
    },
  });

  // Traigo los tipos de alertas que se pueden agregar o borrar.
  const [tiposAgregar, refreshTiposAgregar] = useDataProveedor(`TiposAlertas/`);

  // Traigo los tapires que se pueden agregar.
  const [tapires, refreshTapires] = useDataProveedor(
    `Tapires/?user=${props.user}`
  );

  const onSubmitAgregarTapir = multipleSubmitProveedor({
    urlSubmit: "AlertasUsuarios/",
    thenFunction: () => {
      props.refreshData();
      refreshTapires();
      refreshTiposAgregar();
    },
  });

  const onSubmitConfirmationForm = multipleSubmitProveedor({
    urlSubmit: "AlertasUsuarios/",
    thenFunction: () => {
      props.refreshData();
      refreshTapires();
      refreshTiposAgregar();
    },
  });

  function arrengeDataAgregarTipo(data) {
    // Saco los tipos de alertas que ya están asociados al tapir
    return data.filter((tipo) => {
      const aux = selected.alertasusuarios_set.find(
        (i) => Number(i.TipoAlerta.id) === Number(tipo.id)
      );
      if (aux === undefined) {
        return true;
      }
      return false;
    });
  }

  function arrengeInitialValuesBorrarTapir() {
    if (selected) {
      const initialValues = selected.alertasusuarios_set.map((item, i) => {
        return {
          ...item,
          TipoAlerta: item.TipoAlerta.id,
          FechaHasta: new Date().toISOString().slice(0, 10),
        };
      });
      return initialValues;
    } else {
      return [];
    }
  }

  return (
    <>
      <div style={{ display: "block" }} className={"h2"}>
        <p style={{ display: "inline-block" }} className={"mr-2"}>
          Tapires
        </p>
        <IconButton
          tooltip="Agregar tapir"
          name="agregarTapir"
          icon="paw"
          onClick={showModal}
        />
      </div>

      {props.data ? (
        <ConfigTapiresTabla
          data={props.data}
          onActionClick={(event, tapir) => {
            showModal(event);
            setSelected(tapir);
          }}
        />
      ) : (
        "Cargando..."
      )}

      <UniversalModal
        show={show === "agregarTapir"}
        onHide={hideModal}
        title={"Agregar tapir"}
      >
        <AgregarTapirForm
          initialValues={{
            elegido: "0",
            User: props.user,
          }}
          data={{ tipos: tiposAgregar.data, tapires: tapires.data }}
          onSubmit={(values) => {
            hideModal();
            if (Number(values.elegido) !== 0) {
              onSubmitAgregarTapir(values);
            }
          }}
        />
      </UniversalModal>

      <UniversalModal
        show={show === "agregarTipo"}
        onHide={hideModal}
        title={"Agregar tipo de alerta"}
      >
        <AgregarTipoForm
          initialValues={{
            elegido: "0",
            Tapir: selected,
            User: props.user,
          }}
          data={selected ? arrengeDataAgregarTipo(tiposAgregar.data) : null}
          onSubmit={(values) => {
            hideModal();
            if (Number(values.elegido) !== 0) {
              onSubmitAgregarTipo(values);
            }
          }}
        />
      </UniversalModal>

      <UniversalModal
        show={show === "eliminarTipo"}
        onHide={hideModal}
        title={"Eliminar tipo de alerta"}
      >
        <EliminarTipoForm
          data={selected ? selected.alertasusuarios_set : undefined}
          onSubmit={(values) => {
            hideModal();
            if (Number(values.elegido) !== 0) {
              onSubmitEliminar(values);
            }
          }}
        />
      </UniversalModal>

      <UniversalModal
        show={show === "borrarTapir"}
        onHide={hideModal}
        title={"Borrar tapir"}
      >
        <p>
          ¿Esta seguro que desea dejar de recibir notificaciones de este tapir?
        </p>
        <ConfirmationForm
          initialValues={arrengeInitialValuesBorrarTapir()}
          onSubmit={(values) => {
            hideModal();
            onSubmitConfirmationForm(values);
          }}
          onCancel={hideModal}
        />
      </UniversalModal>
    </>
  );
}

ConfigTapires.propTypes = {
  /* Los datos de la configuración horaria. Proporcionada por DataProveedor */
  data: PropTypes.array,

  /* Actualiza la información en la prop data. Proporcionada por DataProveedor */
  refreshData: PropTypes.func,
};

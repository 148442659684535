import React from "react";
import { getToken } from "./auth";

export const UserContext = React.createContext({
  token: getToken(),
  isAuthenticated: null,
  wasInitialized: false,
  user: null,
  setValues: () => {},
});

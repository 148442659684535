import React from "react";
import { PropTypes } from "prop-types";
import { Button, Modal } from "react-bootstrap";

export class GenericModal extends React.Component {
  state = {
    showDiag: false,
    title: "",
    bodyObj: () => {},
    renderFooter: false,
    yesAction: null,
    size: null
  };

  showDiag = () => {
    this.setState({ showDiag: true });
  };

  hideDiag = () => {
    this.setState({ showDiag: false });
  };

  handleClick = event => {
    event.preventDefault();
    const { name } = event.target;
    const { title, bodyObj, renderFooter, yesAction, size } = this.props;
    this.setState({
      title: title[name],
      bodyObj: bodyObj[name],
      renderFooter: renderFooter[name],
      yesAction: yesAction[name],
      size: size[name]
    });
    this.showDiag();
  };

  handleYes = event => {
    const { yesAction } = this.state;
    event.preventDefault();
    yesAction();
    this.hideDiag();
  };

  render() {
    const { showDiag, title, bodyObj, renderFooter, size } = this.state;
    const { children } = this.props;

    return (
      <>
        <Modal name="diag" show={showDiag} onHide={this.hideDiag} size={size}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{bodyObj({ hideModal: this.hideDiag })}</Modal.Body>
          {renderFooter ? (
            <Modal.Footer>
              <Button variant="danger" onClick={this.handleYes}>
                Si
              </Button>
              <Button variant="secondary" onClick={this.hideDiag}>
                Cancelar
              </Button>
            </Modal.Footer>
          ) : null}
        </Modal>
        {React.Children.map(children, c =>
          React.cloneElement(c, { showModal: this.handleClick })
        )}
      </>
    );
  }
}

GenericModal.propTypes = {
  title: PropTypes.objectOf(PropTypes.string),
  bodyObj: PropTypes.objectOf(PropTypes.func),
  renderFooter: PropTypes.objectOf(PropTypes.bool),
  yesAction: PropTypes.objectOf(PropTypes.func),
  children: PropTypes.node,
  size: PropTypes.objectOf(PropTypes.string)
};

GenericModal.defaultProps = {
  title: {},
  bodyObj: {},
  renderFooter: {},
  yesAction: {},
  size: {}
};

import React from "react";
import Table from "react-bootstrap/Table";

export class TableAlarmas extends React.Component {
  render() {
    const rows = this.props.datos.map((row, id) => {
      return (
        <tr key={id}>
          <td>{row.TipoAlerta.TipoAlerta}</td>
          <td>{row.Tapir.Nombre}</td>
          <td>{row.Fecha}</td>
          <td>{row.Tapir.zonatapir_set[0].ZonaDelimitada.NombreZona}</td>
        </tr>
      );
    });
    return (
      <Table responsive>
        <thead>
          <tr>
            <th>Tipo de Alarma</th>
            <th>Tapir</th>
            <th>Fecha</th>
            <th>Zona</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }
}

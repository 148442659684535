import React from "react";
import MapReferenceList from "./MapReferenceList";

export function MapReference(props) {
  const referencias = props.zonas.map((zona, index) => (
    <MapReferenceList
      key={zona.id}
      zona={{ id: zona.id, nombre: zona.NombreZona }}
      items={zona.zonatapir_set}
    />
  ));
  return referencias;
}

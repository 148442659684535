import React from "react";
import { PropTypes } from "prop-types";
import { IconButton } from "../admin/IconButton";
import ConfigHorarioTabla from "./configHorario/ConfigHorarioTabla";
import UniversalModal from "../commons/UniversalModal";
import ConfigHorarioForm from "./configHorario/ConfigHorarioForm";
import useSubmit from "../commons/useSubmit";
import useUniversalModal from "../commons/useUniversalModal";
import { api_root } from "../utils";

/** Este componente renderiza el layout para la sección
 * de horario de la configuración de alertas de un usuario.
 */
export default function ConfigHorario(props) {
  /** Para manejar el componente UniversalModal */
  const [show, showModal, hideModal] = useUniversalModal();

  const [status, onSubmit] = useSubmit({
    urlSubmit: `${api_root}Perfil/`,
    thenFunction: () => props.refreshData(),
  });

  return (
    <>
      <div style={{ display: "block" }} className={"h2"}>
        <p style={{ display: "inline-block" }} className={"mr-2"}>
          Horario
        </p>
        <IconButton
          tooltip="Editar"
          name="edit"
          icon="edit"
          onClick={showModal}
        />
      </div>
      {props.data ? <ConfigHorarioTabla data={props.data} /> : "Cargando..."}
      <UniversalModal
        show={show === "edit"}
        onHide={hideModal}
        title={"Horarios"}
      >
        <ConfigHorarioForm
          initialValues={props.data}
          onSubmit={(values) => {
            hideModal();
            onSubmit(values);
          }}
        />
      </UniversalModal>
    </>
  );
}

ConfigHorario.propTypes = {
  /* Los datos de la configuración horaria.*/
  data: PropTypes.object,

  /* Actualiza la información en la prop data.*/
  refreshData: PropTypes.func,
};

import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Col, Form, Container, Modal } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import { auth_root } from "./utils";

export class EmailResetForm extends React.Component {
  state = {
    showModalSuccess: false,
    showModalError: false,
    showModalEmptyFields: false,
    msjErr: "",
    msjSuccess: ""
  };

  validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = "Campo requerido";
    }
    return errors;
  };

  handleOnSubmit = values => {
    this.sendEmailResetPassword(values);
    this.setState({ showModalSuccess: true });
  };

  sendEmailResetPassword = values => {
    const body = {
      email: values.email
    };
    axios
      .post(`${auth_root}reset/`, body)
      .then(res => {
        this.setState({ showModalSuccess: true });
        this.setState({ msjSuccess: "Verifique su casilla de email" });
      })
      .catch(err => {
        this.setState({ showModalError: true });
        this.handleError(err.response.status);
      });
  };

  handleError = err => {
    switch (err) {
      case 400:
        this.setState({ msjErr: "Error en la contraseña" });
        break;
      default:
        break;
    }
  };
  modalSuccess = () => {
    const msj = this.state.msjSuccess;
    return (
      <Modal.Dialog>
        <Modal.Header closeButton onClose={this.hideModalSuccess}>
          <Modal.Title>ENVIO EXITOSO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{msj}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.hideModalSuccess}>
            Close
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    );
  };

  hideModalSuccess = () => {
    this.setState({ showModalSuccess: false, success: true });
  };

  modalError = () => {
    const msj = this.state.msjErr;
    return (
      <Modal.Dialog>
        <Modal.Header closeButton onClose={this.hideModalError}>
          <Modal.Title>ERROR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{msj}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.hideModalError}>
            Close
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    );
  };

  hideModalError = () => {
    this.setState({ showModalError: false });
  };

  render() {
    if (this.state.success) {
      return <Redirect to="/" />;
    }
    if (this.state.showModalError) {
      return this.modalError();
    }
    if (this.state.showModalSuccess) {
      return this.modalSuccess();
    }
    return (
      <Container align="center" className="my-3">
        <Col
          md={6}
          className="justify-content-center"
          style={{
            borderStyle: "solid",
            borderWidth: "2px",
            borderColor: "grey"
          }}
        >
          <div className="d-flex justify-content-center my-3">
            <h3>RESETEAR CONTRASEÑA</h3>
          </div>
          <Formik
            initialValues={this.state}
            validate={this.validate}
            onSubmit={this.handleOnSubmit}
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
                  <Form.Label className="d-flex justify-content-start">
                    Por favor, ingresar el mail asociado a su cuenta:
                  </Form.Label>
                  <Form.Control
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Email"
                    {...formik.getFieldProps("email")}
                    value={formik.values.email}
                    isInvalid={formik.errors.email && formik.touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button className="my-3 mr-2" variant="primary" type="submit">
                  Aceptar
                </Button>
                <Button className="my-3" variant="primary" href="/login">
                  Cancelar
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Container>
    );
  }
}

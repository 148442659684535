import axios from "axios";

/** Devuelve una función con la lógica para actualizar los datos del perfil del usuario */
export default function submitFuncProvider(props) {
  function onSubmit(values) {
    const { user, perfil } = values;

    const patchUser = () =>
      axios.patch(props.urlUser + `${user ? user.id + "/" : ""}`, user);

    const putPerfil = () =>
      axios.put(props.urlPerfil + `${perfil ? perfil.id + "/" : ""}`, perfil);

    if (user && perfil) {
      axios
        .all([patchUser(), putPerfil()])
        .then(() => {
          if (props.thenFunction) {
            props.thenFunction();
          }
        })
        .catch((err) => console.error(err));
    } else {
      if (user) {
        patchUser().then(() => {
          if (props.thenFunction) {
            props.thenFunction();
          }
        });
      }
      if (perfil) {
        putPerfil().then(() => {
          if (props.thenFunction) {
            props.thenFunction();
          }
        });
      }
    }
  }

  return onSubmit;
}

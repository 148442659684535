/** Este módulo contiene funciones para
 * la autenticación de la aplicación.
 */
import axios from "axios";
import { api_root } from "./utils";

export function getToken() {
  let token = localStorage.getItem("token");
  if (token === null || token === undefined) {
    token = sessionStorage.getItem("token");
  }
  return token;
}

function local_logout(setValues) {
  // Se borra el token del almacenamiento del navegador
  localStorage.removeItem("token");
  sessionStorage.removeItem("token");

  // Se borra el token de la configuración de la librería (axios)
  delete axios.defaults.headers.common["Authorization"];

  // Se actualiza el estado de la aplicación
  setValues({
    token: null,
    isAuthenticated: false,
    user: null,
  });
}

export function logout(setValues) {
  axios.post(`${api_root}logout/`).catch((err) => {
    console.error(err);
  });
  local_logout(setValues);
}

export function setupInterceptors(setValues) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        local_logout(setValues);
      }
      return Promise.reject(error);
    }
  );
}

export async function init_auth(setValues) {
  const token = getToken();
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
    // Valido el token
    try {
      const res = await axios.get(`${api_root}user/`);
      if (res.data.id === null) {
        // Si se hace una petición sin token (no debería pasar),
        // la api devuelve el usuario anónimo con id=null
        local_logout(setValues);
      } else {
        // Token validado
        setValues({
          token: token,
          isAuthenticated: true,
          user: res.data,
        });
      }
    } catch (err) {
      // El interceptor de axios se encarga de hacer el logout local
      console.error(err);
    }
  }
  setValues({ wasInitialized: true });
}

import axios from "axios";
import { api_root } from "../../utils";

export function postFotos(token, foto) {
  axios
    .post(`${api_root}Fotos/`, foto, {
      headers: { Authorization: `Token ${token}` },
    })
    .catch((err) => console.error(err));
}

export class TapirManager {
  static all = (token, thenFunction) => {
    axios
      .get(`${api_root}ListTapir/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => thenFunction(res))
      .catch((err) => console.error(err));
  };

  static delete = (token, tapir, thenFunction, catchFunction) => {
    axios
      .delete(`${api_root}Tapires/${tapir.id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => thenFunction(res))
      .catch((err) => {
        catchFunction(err);
      });
  };

  static editOrCreate = (token, tapir, thenFunction) => {
    const url = `${api_root}Tapires/${tapir.id ? tapir.id + "/" : ""}`;
    const method = `${tapir.id ? "put" : "post"}`;
    const data = {
      Nombre: tapir.Nombre,
      FechaNacimiento: tapir.FechaNacimiento,
      Descripcion: tapir.Descripcion,
    };

    axios
      .request({
        url,
        method,
        data,
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => {
        if (tapir.Foto) {
          const fd = new FormData();
          fd.append("foto", tapir.Foto, tapir.Foto.name);
          fd.append("tapir", res.data.id);
          postFotos(token, fd);
        }
        thenFunction(res);
      })
      .catch((err) => console.error(err));
  };

  static dejarDeSeguir = (token, tapirCollar, thenFunction) => {
    const date = new Date();
    axios
      .put(
        `${api_root}TapiresCollares/${tapirCollar.id}/`,
        {
          FechaInicio: tapirCollar.FechaInicio,
          FechaFin: date.toISOString().slice(0, 10),
          Tapir: tapirCollar.Tapir,
          Collar: tapirCollar.Collar.id,
        },
        { headers: { Authorization: `Token ${token}` } }
      )
      .then((res) => thenFunction(res))
      .catch((err) => console.error(err));
  };

  //'problematicos': Entradas de la tabla con "FechaInicio"=HOY y "FechaFin"=NOT_NULL
  static seguir = (token, collar, tapir, problematicos, thenFunction) => {
    //Busco si el tapir y el collar se encuentran entre los problemáticos
    //La funcion 'filter' devuelve un arreglo. Para nuestro caso, lo devuelve vacio o con un elemento. Por eso el [0] al final.
    const esProblematico = problematicos.filter(
      (p) =>
        Number(p.Tapir) === Number(tapir) && Number(p.Collar) === Number(collar)
    )[0];
    //Armo la request
    const url = `${api_root}TapiresCollares/${
      esProblematico ? esProblematico.id + "/" : ""
    }`;
    const method = `${esProblematico ? "put" : "post"}`;
    const data = {
      FechaInicio: new Date().toISOString().slice(0, 10),
      FechaFin: null,
      Tapir: tapir,
      Collar: collar,
    };

    axios
      .request({
        url,
        method,
        data,
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => thenFunction(res))
      .catch((err) => console.error(err));
  };

  static collaresLibres = (token, thenFunction) => {
    axios
      .get(`${api_root}Collares/?soloLibres=true`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => thenFunction(res))
      .catch((err) => console.error(err));
  };

  static collaresProblematicos = (token, thenFunction) => {
    axios
      .get(`${api_root}TapiresCollares/?soloProblematicos=true`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => thenFunction(res))
      .catch((err) => console.error(err));
  };
}

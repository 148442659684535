import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./sidebar.css";

class Sidebar extends React.Component {
  render() {
    return (
      <ListGroup
        style={{ height: "90vh" }}
        variant="flush"
        defaultActiveKey={Number(this.props.activeKey)}
      >
        <ListGroup.Item
          className="bg-transparent"
          action
          to="../admin/tapires"
          eventKey={1}
          as={Link}
        >
          <FontAwesomeIcon icon="paw" />
          {" Tapires"}
        </ListGroup.Item>
        <ListGroup.Item
          className="bg-transparent"
          action
          to="../admin/collares"
          eventKey={2}
          as={Link}
        >
          <FontAwesomeIcon icon="circle-notch" />
          {" Collares"}
        </ListGroup.Item>
        <ListGroup.Item
          className="bg-transparent"
          action
          to="../admin/zonas"
          eventKey={3}
          as={Link}
        >
          <FontAwesomeIcon icon="vector-square" />
          {" Zonas"}
        </ListGroup.Item>
        <ListGroup.Item
          className="bg-transparent"
          action
          to="../admin/usuarios"
          eventKey={4}
          as={Link}
        >
          <FontAwesomeIcon icon="users" />
          {" Usuarios"}
        </ListGroup.Item>
      </ListGroup>
    );
  }
}

export default Sidebar;

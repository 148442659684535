import React from "react";
import { PropTypes } from "prop-types";
import SelectForm from "../../../commons/SelectForm";

/** Este componente renderiza un formulario para eliminar un Tipo de alerta
 * de la configuración de alertas de un usuario.
 */
export default function EliminarTipoForm(props) {
  /** Acomoda los datos de cierta manera. */
  function arrengeData(values) {
    values = JSON.parse(values.elegido);
    let aux = {
      ...values,
      FechaHasta: new Date().toISOString().slice(0, 10),
      TipoAlerta: values.TipoAlerta.id,
    };
    return aux;
  }

  /** Función que se ejecta al hacer click
   * en el botón "Guardar" (o equivalente) del Form.
   */
  function onSubmit(values) {
    props.onSubmit(arrengeData(values));
  }

  const { data } = props;

  // Opciones para llenar el menú desplegable (SelectForm).
  const options = data.map((option) => {
    return (
      <option key={option.id} value={JSON.stringify(option)}>
        {option.TipoAlerta.TipoAlerta}
      </option>
    );
  });

  return (
    <SelectForm
      options={options}
      initialValues={props.initialValues}
      onSubmit={onSubmit}
    />
  );
}

EliminarTipoForm.propTypes = {
  /** Las opciones que se mostrarán en el menú desplegable.*/
  data: PropTypes.array,

  /** Requerido por Formik */
  initialValues: PropTypes.shape({
    /* Elegido por defecto en el menú desplegable. Valor recomendado: "0" */
    elegido: PropTypes.string,
  }),

  /** Función que realiza el POST o PUT con los datos.*/
  onSubmit: PropTypes.func,
};

EliminarTipoForm.defaultProps = {
  initialValues: {
    elegido: "0",
  },
};

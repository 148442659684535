import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Navbarcomp } from "./Navbarcomp";
import { Row, Col } from "react-bootstrap";
import { UserContext } from "./UserContext";
import { LoginPage } from "./LoginPage";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import RegisterPage from "./RegisterPage";
import { api_root } from "./utils";
import { Alarmas } from "./Alarmas";
import { HomePage } from "./HomePage";
import { AdminHome } from "./admin/AdminHome";
import { PasswordResetForm } from "./PasswordResetForm";
import PerfilLayout from "./perfil/PerfilLayout";
import { ChangePasswordForm } from "./changePasswordForm";
import { EmailResetForm } from "./EmailResetForm";
import PageFooter from "./PageFooter";
import { Page404 } from "./Page404";
import "./iconos";
import axios from "axios";
import { getToken, setupInterceptors, init_auth } from "./auth";

export class App extends React.Component {
  setValues = (props) => {
    if (props.token === undefined) {
      props.token = this.state.token;
    }
    if (props.isAuthenticated === undefined || props.isAuthenticated === null) {
      props.isAuthenticated = this.state.isAuthenticated;
    }
    if (props.user === undefined) {
      props.user = this.state.user;
    }
    if (props.wasInitialized === undefined) {
      props.wasInitialized = this.state.wasInitialized;
    }
    this.setState({
      token: props.token,
      isAuthenticated: props.isAuthenticated,
      user: props.user,
      wasInitialized: props.wasInitialized,
    });
  };

  state = {
    token: getToken(),
    isAuthenticated: false,
    user: null,
    setValues: this.setValues,
    wasInitialized: false,
  };

  componentDidMount() {
    axios.defaults.baseURL = api_root;
    setupInterceptors(this.setValues);
    init_auth(this.setValues);
  }

  componentWillUnmount() {
    delete axios.defaults["baseURL"];
    delete axios.defaults.headers.common["Authorization"];
  }

  render() {
    let isAuthenticated = this.state.isAuthenticated;
    let wasInitialized = this.state.wasInitialized;
    return (
      <UserContext.Provider value={this.state}>
        <Col>
          <Router>
            <Row>
              <Navbarcomp data={this.state} />
            </Row>
            <Row style={{ minHeight: "90vh" }}>
              <Switch>
                <Redirect from={"/home"} to={"/"} />
                <PublicRoute
                  restricted={true}
                  wasInitialized={wasInitialized}
                  isAuth={isAuthenticated}
                  component={LoginPage}
                  path="/login"
                  exact
                />
                <PrivateRoute
                  component={HomePage}
                  isAuth={isAuthenticated}
                  path="/"
                  exact
                />
                <PublicRoute
                  restricted={true}
                  wasInitialized={wasInitialized}
                  isAuth={isAuthenticated}
                  exact
                  path="/register"
                  component={RegisterPage}
                />
                <PrivateRoute
                  component={Alarmas}
                  isAuth={isAuthenticated}
                  exact
                  path="/alarmas"
                />
                <PrivateRoute
                  isAuth={isAuthenticated}
                  path="/admin"
                  component={AdminHome}
                />
                <PrivateRoute
                  isAuth={isAuthenticated}
                  exact
                  path="/perfil"
                  component={PerfilLayout}
                />
                <PrivateRoute
                  isAuth={isAuthenticated}
                  exact
                  path="/perfil/edit"
                  component={() => (
                    <ChangePasswordForm token={this.state.token} />
                  )}
                />
                <PublicRoute
                  isAuth={isAuthenticated}
                  wasInitialized={wasInitialized}
                  restricted={true}
                  path="/Trackpir/reset/:uid/:token"
                  component={PasswordResetForm}
                />
                <PublicRoute
                  isAuth={isAuthenticated}
                  wasInitialized={wasInitialized}
                  restricted={true}
                  path="/reset_email"
                  component={EmailResetForm}
                />
                <PublicRoute
                  isAuth={isAuthenticated}
                  wasInitialized={wasInitialized}
                  restricted={false}
                  component={Page404}
                />
              </Switch>
            </Row>

            <Row className="bg-dark text-muted p-3">
              <PageFooter />
            </Row>
          </Router>
        </Col>
      </UserContext.Provider>
    );
  }
}

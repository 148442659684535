import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PublicRoute = ({
  component: Component,
  wasInitialized,
  isAuth,
  restricted,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!wasInitialized && restricted) {
        return "Cargando...";
      }
      if (isAuth && restricted) {
        return <Redirect to="/" />;
      }
      return <Component {...props} />;
    }}
  />
);

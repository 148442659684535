import { useState } from "react";

/** Este hook contiene la lógica para trabajar con
 * el componente UniversalModal.
 */
export default function useUniversalModal() {
  const [show, setShow] = useState(null);

  function showModal(event) {
    event.preventDefault();
    setShow(event.target.name);
  }

  function hideModal() {
    setShow(null);
  }

  return [show, showModal, hideModal];
}

import React from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { UserContext } from "./UserContext";
import { api_root } from "./utils";

export class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
    remember: true,
    didLoginFail: false,
  };

  static contextType = UserContext;

  onSubmit = (event) => {
    event.preventDefault();
    this.login();
  };

  onChange = (event) => {
    if (String([event.target.name]) === "remember") {
      this.setState({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  login = () => {
    const body = {
      username: this.state.username,
      password: this.state.password,
    };
    axios
      .post(`${api_root}login/`, body)
      .then((res) => {
        //LOGIN_SUCCESS
        this.state.remember
          ? localStorage.setItem("token", res.data.token)
          : sessionStorage.setItem("token", res.data.token);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Token ${res.data.token}`;
        this.context.setValues({
          token: res.data.token,
          isAuthenticated: true,
          user: res.data.user,
        });
      })
      .catch((err) => {
        this.setState({ didLoginFail: true });
      });
  };

  render() {
    const { username, password } = this.state;
    const loginFailMessage = () => {
      if (this.state.didLoginFail) {
        return (
          <div className="d-flex justify-content-center text-danger">
            Usuario o contraseña inválidos
          </div>
        );
      } else {
        return "";
      }
    };
    //
    return (
      <>
        <div className="d-flex justify-content-center mb-3">
          <h3>LOGIN</h3>
        </div>
        <Form onSubmit={this.onSubmit}>
          <Form.Group>
            <Form.Control
              className="bg-light"
              type="text"
              placeholder="Usuario"
              name="username"
              onChange={this.onChange}
              value={username}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              className="bg-light"
              type="password"
              placeholder="Contraseña"
              name="password"
              onChange={this.onChange}
              value={password}
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between">
            <Form.Check
              type="checkbox"
              label="Recordarme"
              defaultChecked={this.state.remember}
              name="remember"
              onChange={this.onChange}
            />
            <Link to="/reset_email" className="ml-3">
              Olvide la contraseña
            </Link>
          </Form.Group>
          {loginFailMessage()}
          <Button className="my-3" type="submit" style={{ width: "100%" }}>
            Ingresar
          </Button>
          <div className="d-flex justify-content-center">
            No registrado?
            <p>
              <Link to="/register" className="ml-1">
                Crear Cuenta
              </Link>
            </p>
          </div>
        </Form>
      </>
    );
  }
}

import axios from "axios";
import { api_root } from "../../utils";

export class CollarManager {
  static all = (token, thenFunction) => {
    axios
      .get(`${api_root}Collares/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => thenFunction(res))
      .catch((err) => console.error(err));
  };

  static delete = (token, collar, thenFunction, catchFunction) => {
    axios
      .delete(`${api_root}Collares/${collar.id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => thenFunction(res))
      .catch((err) => {
        catchFunction(err);
      });
  };

  static editOrCreate = (token, collar, thenFunction) => {
    const url = `${api_root}Collares/${collar.id ? collar.id + "/" : ""}`;
    const method = `${collar.id ? "put" : "post"}`;
    const data = {
      Nombre: collar.Nombre,
      MACAddress: collar.MACAddress,
      Observacion: collar.Observacion,
    };
    axios
      .request({
        url,
        method,
        data,
        headers: { Authorization: `Token ${token}` },
      })
      .then((res) => thenFunction(res))
      .catch((err) => console.error(err));
  };
}

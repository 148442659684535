import { useState, useEffect } from "react";
import axios from "axios";

/**
 * Contiene la lógica para traer datos haciendo un GET.
 */
export default function useDataProveedor(urlGet) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    axios
      .get(urlGet)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [urlGet, trigger]);

  function triggerEffect() {
    setTrigger(!trigger);
  }

  return [{ data, isLoading, isError }, triggerEffect];
}

import axios from "axios";
import { api_root } from "./utils"

export const refreshTapires = (token, zona, thenFunction) => {
  axios
    .get(`${api_root}FilterbarTapires/${zona ? "?zona=" + zona : ""}`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then(res => thenFunction(res))
    .catch(err => console.error(err));
};

export const refreshZonas = (token, thenFunction) => {
  axios
    .get(`${api_root}ZonasDelimitadas/`, {
      headers: { Authorization: `Token ${token}` }
    })
    .then(res => thenFunction(res))
    .catch(err => console.error(err));
};

import React from "react";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import { Formik } from "formik";
import { PropTypes } from "prop-types";

/** Este componente renderiza un formulario para actualizar
 * el horario de la configuración de alertas de un usuario.
 */
export default function ConfigHorarioForm(props) {
  /** Acomoda los datos que serán enviados en la request */
  function arrengeData(values) {
    return {
      ...values,
      HoraHasta: values.HoraHasta + ":59",
    };
  }

  /** Función que se ejecuta al hacer clic en el botón "Guardar" */
  function onSubmit(values) {
    props.onSubmit(arrengeData(values));
  }

  const dias = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
  ];

  return (
    <Formik initialValues={props.initialValues} onSubmit={onSubmit}>
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Label>Hora Desde</Form.Label>
            <Form.Control
              type="time"
              id="HoraDesde"
              name="HoraDesde"
              {...formik.getFieldProps("HoraDesde")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Hora Hasta</Form.Label>
            <Form.Control
              type="time"
              id="HoraHasta"
              name="HoraHasta"
              {...formik.getFieldProps("HoraHasta")}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Dias</Form.Label>
            {dias.map((dia) => (
              <Form.Check
                key={dia}
                type="checkbox"
                id={dia}
                name={dia}
                label={dia}
                value={formik.values[dia]}
                checked={formik.values[dia]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            ))}
          </Form.Group>
          <ButtonGroup>
            <Button type="submit">Guardar</Button>
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
}

ConfigHorarioForm.propTypes = {
  /** <optional> Los valores iniciales de los campos del form */
  initialValues: PropTypes.shape({
    HoraDesde: PropTypes.string,
    HoraHasta: PropTypes.string,
    Dias: PropTypes.array,
  }),
  /** Función con la lógica para mandar una request */
  onSubmit: PropTypes.func,
};

ConfigHorarioForm.defaultProps = {
  initialValues: {
    HoraDesde: "",
    HoraHasta: "",
    Dias: [],
  },
  onSubmit: () => {},
};

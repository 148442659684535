import React from "react";
import Table from "react-bootstrap/Table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CSVLink } from "react-csv";

export class TableMediciones extends React.Component {
  state = {
    mediciones: [],
    data: [],
  };

  componentDidMount() {
    this.arrayOfArraysToArray();
    this.datosParaExportar();
  }

  arrayOfArraysToArray = () => {
    var paratabla = [];
    const posiciones = this.props.posiciones.map((posicion, id) => {
      posicion.map(row =>{
          paratabla.push(row)
        });
    });
    const mediciones = paratabla;
    this.setState({ mediciones })
  };

  datosParaExportar = () => {
    var vector = [];
    const cargar = this.props.posiciones.map((row, id) => {
      row.map(tapir =>{
        vector.push([tapir.latLng.lat, tapir.latLng.lng])
        });
    });
    const data = vector;
    this.setState({ data });
  };

  exportPDF = () => {
    const datos = this.props.posiciones;
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Reporte de Mediciones: ";
    const headers = [["Tapir", "Latitud", "Longitud", "Fecha", "Collar"]];

    const data = datos.map(a => [
      a.nombre.tapir,
      a.latLng.lat,
      a.latLng.lng,
      a.nombre.fecha,
      a.nombre.collar,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    /*
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");*/
  };

  render() {
    const datosTabla = this.state.mediciones.map((dato,id) => {
      return (
        <tr key={id}>
          <td>{dato.nombre.tapir.Nombre}</td>
          <td>{dato.latLng.lat}</td>
          <td>{dato.latLng.lng}</td>
          <td>{dato.nombre.fecha}</td>
          <td>{dato.nombre.collar}</td>
        </tr>
    )
    });

    return (
      <>
        <style type="text/css">
          {`
          .my-custom-scrollbar {
            position: relative;
            height: 400px;
            overflow: auto;
            weight: 100%;

          }
          .table-wrapper-scroll-y {
            display: block;
          }
    `}
        </style>
        <Table
          bsPrefix="table-wrapper-scroll-y my-custom-scrollbar"
          className="table table-bordered table-striped mb-0"
          responsive
        >
          <thead>
            <tr>
              <th>Tapir</th>
              <th>Latitud</th>
              <th>Longitud</th>
              <th>Fecha</th>
              <th>Collar</th>
            </tr>
          </thead>
          <tbody>{datosTabla}</tbody>
        </Table>
        <CSVLink
          data={this.state.data}
          className="btn btn-danger"
          filename={"mediciones.csv"}
        >
          Exportar a CSV
        </CSVLink>
      </>
    );
  }
}

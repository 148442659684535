import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { UserContext } from "./UserContext";
import { api_root } from "./utils.js";
import axios from "axios";

export class ImagenAlbum extends React.Component {
  _isMounted = false;

  static contextType = UserContext;

  state = {
    src: "https://via.placeholder.com/286x180",
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    const imagenes = this.props.imagenes;
    if (imagenes && imagenes.length !== 0) {
      axios
        .get(`${api_root}Fotos/?foto=${imagenes[0].foto}`, {
          headers: {
            Authorization: `Token ${this.context.token}`,
          },
        })
        .then((res) => {
          this._isMounted &&
            this.setState({
              src: "data:image/*;base64," + res.data,
            });
        });
    }
  }

  render() {
    return (
      <Card.Img
        style={{ height: "160px" }}
        variant="top"
        src={this.state.src}
      />
    );
  }
}

ImagenAlbum.propTypes = {
  imagenes: PropTypes.arrayOf(
    PropTypes.shape({
      tapir: PropTypes.number,
      foto: PropTypes.string,
    })
  ),
};

import React from "react";
import { Table } from "react-bootstrap";
import { PropTypes } from "prop-types";

/** Este componenete renderiza una tabla para mostrar
 * el horario de la configuración de alertas de un usuario.
 */
export default function ConfigHorarioTabla(props) {
  const { data } = props;

  return (
    <div>
      <Table>
        <tbody>
          <tr>
            <th className="text-right">Hora</th>
            <td>
              {data.HoraDesde.slice(0, 5) + " - " + data.HoraHasta.slice(0, 5)}
            </td>
          </tr>
        </tbody>
      </Table>
      <Table size={"sm"}>
        <thead>
          <tr>
            <th>Lunes</th>
            <th>Martes</th>
            <th>Miercoles</th>
            <th>Jueves</th>
            <th>Viernes</th>
            <th>Sabado</th>
            <th>Domingo</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.Lunes ? "OK" : "--"}</td>
            <td>{data.Martes ? "OK" : "--"}</td>
            <td>{data.Miercoles ? "OK" : "--"}</td>
            <td>{data.Jueves ? "OK" : "--"}</td>
            <td>{data.Viernes ? "OK" : "--"}</td>
            <td>{data.Sabado ? "OK" : "--"}</td>
            <td>{data.Domingo ? "OK" : "--"}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

ConfigHorarioTabla.propTypes = {
  /** Información a mostrar en la tabla.*/
  data: PropTypes.shape({
    HoraDesde: PropTypes.string,
    HoraHasta: PropTypes.string,
    Lunes: PropTypes.bool,
    Martes: PropTypes.bool,
    Miercoles: PropTypes.bool,
    Jueves: PropTypes.bool,
    Viernes: PropTypes.bool,
    Sabado: PropTypes.bool,
    Domingo: PropTypes.bool,
  }),
};

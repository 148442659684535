import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faInstagramSquare,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEdit,
  faTrashAlt,
  faPaw,
  faMapMarkerAlt,
  faVectorSquare,
  faPlusCircle,
  faCircleNotch,
  faCircle,
  faBell,
  faUsers,
  faUserShield,
  faLock,
  faLockOpen,
  faBirthdayCake,
  faLaptopCode,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

import { faDotCircle } from "@fortawesome/free-regular-svg-icons";

library.add(
  fab,
  faEdit,
  faTrashAlt,
  faPaw,
  faMapMarkerAlt,
  faVectorSquare,
  faPlusCircle,
  faCircleNotch,
  faCircle,
  faBell,
  faUsers,
  faUserShield,
  faLock,
  faLockOpen,
  faBirthdayCake,
  faLaptopCode,
  faInstagramSquare,
  faFacebook,
  faArrowCircleLeft,
  faDotCircle
);

import React from "react";
import { PropTypes } from "prop-types";
import { Form, Col, Button } from "react-bootstrap";
import { refreshTapires, refreshZonas } from "./FilterbarRequests";
import { UserContext } from "./UserContext";

/*
 * Estado de Filterbar:
 *   zonas: arreglo para almacenar las zonas de la base de datos.
 *   tapires: arreglo para almacenar los tapires de la base de datos.
 */
export class Filterbar extends React.Component {
  _isMounted = false;
  static contextType = UserContext;
  state = {
    zonas: [],
    tapires: [],
    filterZona: 0,
    filterTapir: 0,
    filterHora: 0,
    filterFechaDesde: null,
    filterFechaHasta: null,
    startDate: null,
    finishDate: null,
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.context.isAuthenticated) {
      refreshZonas(this.context.token, res => {
        this._isMounted && this.setState({ zonas: res.data });
      });
      refreshTapires(this.context.token, null, res => {
        this._isMounted && this.setState({ tapires: res.data });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
   * Este método se define para manejar el select de zonas
   */
  handleSelectZona = event => {
    const valor = Number(event.target.value);
    this.setState({ filterZona: valor });
    refreshTapires(this.context.token, valor, res => {
      this._isMounted && this.setState({ tapires: res.data });
    });
  };

  /*
   * Este método se define para manejar el select de tapires
   */
  handleSelectTapir = event => {
    const selectedId = Number(event.target.value);
    let zona = 0;
    if (selectedId) {
      const tapires = this.state.tapires.filter((tapir, index) => {
        return tapir.id === selectedId;
      });
      zona = tapires[0].zonatapir_set[0].ZonaDelimitada.id;
    }
    this.setState({ filterTapir: selectedId });
    this.setState({ filterZona: zona });
  };

  /*
   * Este método se define para manejar el submit del form
   */
  handleSubmit = event => {
    event.preventDefault();
    const filter = {
      zona: this.state.filterZona,
      tapir: this.state.filterTapir,
      fechaInicio: this.state.startDate,
      fechaFin: this.state.finishDate
    };
    this.props.setFilter(filter);
  };

  handleInitialDate = event => {
    this.setState({
      startDate : event.target.value
    })
  }

  handleEndDate = event => {
    this.setState({
      finishDate : event.target.value
    })
  }

  render() {
    const optionsZona = this.state.zonas.map(op => {
      return (
        <option key={op.id} value={op.id}>
          {op.NombreZona}
        </option>
      );
    });

    const optionsTapir = this.state.tapires.map(op => {
      return (
        <option key={op.id} value={op.id}>
          {op.Nombre}
        </option>
      );
    });

    return (
      <>
      <Col>
        <Form className="py-2" onSubmit={this.handleSubmit}>
          <Form.Row className="justify-content-center">
            <Form.Group as={Col} xs={12} md={"auto"}>
              <Form.Label>Zona</Form.Label>
              <Form.Control
                as="select"
                defaultValue={this.state.filterZona}
                onChange={this.handleSelectZona}
              >
                <option value={0}>Todas</option>
                {optionsZona}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} md={2}>
              <Form.Label>Tapir</Form.Label>
              <Form.Control
                as="select"
                type="text"
                onChange={this.handleSelectTapir}
              >
                <option value={0}>Todos</option>
                {optionsTapir}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} xs={12} md={2}>
              <Form.Label>Fecha desde</Form.Label>
              <Form.Control
                as="input"
                type="Date"
                onChange={this.handleInitialDate}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} md={2}>
              <Form.Label>Fecha hasta</Form.Label>
              <Form.Control
                as="input"
                type="Date"
                onChange={this.handleEndDate}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} md={"auto"} className="align-self-end">
              <Button variant="primary" type="submit">
                Aplicar
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </Col>

      </>
    );
  }
}

Filterbar.propTypes = {
  setFilter: PropTypes.func
};

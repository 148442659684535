import React from "react";
import { PropTypes } from "prop-types";
import SelectForm from "../../commons/SelectForm";

/** Este componente renderiza un form para cambiar el permiso o grupo del usuario */
export default function PermisosForm(props) {
  /** Acomoda los datos que serán enviados en la request */
  function arrengeData(values) {
    return {
      user: values.user,
      permiso: values.elegido,
    };
  }

  /** Función que se ejecta al hacer click
   * en el botón "Guardar" (o equivalente) del Form.
   */
  function onSubmit(values) {
    props.onSubmit(arrengeData(values));
  }

  const { data } = props;

  // Opciones para llenar el menú desplegable.
  const options = data.map((option) => {
    return (
      <option key={option.id} value={option.id}>
        {option.Nombre}
      </option>
    );
  });

  return (
    <SelectForm
      options={options}
      initialValues={props.initialValues}
      onSubmit={onSubmit}
    />
  );
}

PermisosForm.propTypes = {
  /** Las opciones que se mostrarán en el menú desplegable.
   * Proporcionada por AgregarTapirDataProveedor.
   */
  data: PropTypes.array,

  /** Requerido por Formik */
  initialValues: PropTypes.shape({
    /* Elegido por defecto en el menú desplegable. Valor recomendado: "0" */
    elegido: PropTypes.string,
    /* user al que se agregará el tipo */
    User: PropTypes.number,
  }),

  /** Función que realiza el POST o PUT con los datos.
   * Proporcionada por BorrarTapirProveedor.
   */
  onSubmit: PropTypes.func,
};

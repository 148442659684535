import React from "react";
import { Table } from "react-bootstrap";
import { IconButton } from "../IconButton";

export class ZonaTable extends React.Component {
  render() {
    const rows = this.props.values.map((zona, index) => {
      const onClick = event => {
        event.preventDefault();
        this.props.setSelected(zona);
        this.props.showModal(event);
      };
      const puntos = zona.puntoszona_set.map(punto => {
        return (
          <li key={punto.id}>
            {"(" + punto.Latitud + "," + punto.Longitud + ")"}
          </li>
        );
      });
      const tapires = zona.zonatapir_set.map((tapir, index) => {
        return <li key={tapir.id}>{tapir.Tapir.Nombre}</li>;
      });
      const propsBtnPuntos = {};
      if (puntos && puntos.length) {
        propsBtnPuntos.name = "borrarPuntos";
        propsBtnPuntos.color = "red";
        propsBtnPuntos.tooltip = "Borrar límites";
      } else {
        propsBtnPuntos.name = "agregarPuntos";
        propsBtnPuntos.color = "green";
        propsBtnPuntos.tooltip = "Definir límites";
      }
      let showEliminarTapir = false;
      if (tapires && tapires.length) {
        showEliminarTapir = true;
      }
      return (
        <tr key={zona.id}>
          <td>{zona.NombreZona}</td>
          <td>
            <ol>{tapires}</ol>
          </td>
          <td>
            <ol>{puntos}</ol>
          </td>
          <td>
            <IconButton
              name={propsBtnPuntos.name}
              tooltip={propsBtnPuntos.tooltip}
              icon="map-marker-alt"
              color={propsBtnPuntos.color}
              onClick={onClick}
            />
            <IconButton
              tooltip="Agregar tapir a la zona"
              name="agregarTapires"
              icon="paw"
              color="green"
              onClick={onClick}
            />
            {showEliminarTapir ? (
              <IconButton
                tooltip="Borrar tapir de la zona"
                name="borrarTapires"
                icon="paw"
                color="red"
                onClick={onClick}
              />
            ) : null}
            <IconButton
              tooltip="Editar"
              name="crearEditar"
              icon="edit"
              onClick={onClick}
            />
            <IconButton
              tooltip="Borrar"
              name="borrarZona"
              icon="trash-alt"
              onClick={onClick}
            />
          </td>
        </tr>
      );
    });

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Tapires</th>
            <th>Puntos</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }
}

ZonaTable.defaultProps = {
  //values: cada elemento del arreglo es un zona.
  values: [
    {
      id: "defaultId",
      NombreZona: "",
      Tapires: "",
      Puntos: ""
    }
  ],
  setSelected: () => {},
  showModal: () => {}
};

import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { UserContext } from "../UserContext";
import DatosPerfil from "./DatosPerfil";
import ConfigHorario from "./ConfigHorario";
import ConfigTapires from "./ConfigTapires";
import useDataProveedor from "../commons/useDataProveedor";

/* Usada para acomodar los datos traidos para el componente DatosPerfil */
const arrengeData = (data) => {
  const { user, perfil } = data;
  return {
    idUser: user.id,
    username: user.username,
    email: user.email,
    idPerfil: perfil[0].id,
    Nombre: perfil[0].Nombre,
    Apellido: perfil[0].Apellido,
    Telefono: perfil[0].Telefono,
    User: perfil[0].User,
    HoraDesde: perfil[0].HoraDesde,
    HoraHasta: perfil[0].HoraHasta,
    Lunes: perfil[0].Lunes,
    Martes: perfil[0].Martes,
    Miercoles: perfil[0].Miercoles,
    Jueves: perfil[0].Jueves,
    Viernes: perfil[0].Viernes,
    Sabado: perfil[0].Sabado,
    Domingo: perfil[0].Domingo,
  };
};

/** Este componente renderiza el layout para el perfil de un usuario.*/
export default function PerfilLayout(props) {
  const context = useContext(UserContext);

  /** Se traen datos para el componente ConfigTapires */
  const [tapires, refreshTapires] = useDataProveedor(
    `UsuarioAdmin/?user=${context.user.id}`
  );

  /** Se traen datos para el componente DatosPerfil */
  const [user, refreshUser] = useDataProveedor(`User/${context.user.id}/`);

  /** Se traen datos para los componentes DatosPerfil y ConfigHorario */
  const [perfil, refreshPerfil] = useDataProveedor(
    `Perfil/?User=${context.user.id}`
  );

  return (
    <Col>
      <Row>
        <Col>
          <DatosPerfil
            data={
              user.data && perfil.data
                ? arrengeData({ user: user.data, perfil: perfil.data })
                : null
            }
            refreshData={() => {
              refreshUser();
              refreshPerfil();
            }}
          />
        </Col>
      </Row>

      <Row
        style={{
          display:
            context.user !== null &&
            context.user.groups !== undefined &&
            context.user.groups[0] !== undefined &&
            context.user.groups[0].Nombre === "patrocinador"
              ? "none"
              : "",
        }}
      >
        <Col>
          <h1>Configuración de alertas</h1>
          <Col>
            <ConfigHorario
              data={perfil.data ? { ...perfil.data[0] } : null}
              refreshData={refreshPerfil}
            />
          </Col>
          <Col>
            <ConfigTapires
              user={context.user.id}
              data={tapires.data ? tapires.data : null}
              refreshData={refreshTapires}
            />
          </Col>
        </Col>
      </Row>
    </Col>
  );
}

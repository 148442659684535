import React from "react";
import {
  Col,
  Row,
  ListGroup,
  Button,
  Modal,
  Navbar,
  Table,
  ButtonToolbar,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";
import LeafletMap from "./leaflet/LeafletMap";
import { Filterbar } from "./Filterbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MapReference } from "./MapReference";
import axios from "axios";
import { getColor, getSquare, api_root } from "./utils";
import { TableMediciones } from "./TableMediciones";
import { UserContext } from "./UserContext";
import { formatDate } from "./Alarmas";
import { Album } from "./Album";
import "./my.css";
import { ImagenAlbum } from "./ImagenAlbum";

export class HomePage extends React.Component {
  _isMounted = false;
  intervalIDM; //id para poder parar el intervalo de tiempo
  intervalIDP;
  intervalIDB;
  static contextType = UserContext;
  state = {
    zonas: [],
    puntosZonas: [],
    cuadrados: [],
    mediciones: [],
    baterias: [],
    filter: {
      zona: 0,
      tapir: 0,
      fechaInicio: null,
      fechaFin: null,
    },
    activeTab: 1,
    table: true,
    showBaterias: false,
    showFilterbar: false,
    open: true,
    showDetalleTapir: false,
    selectedTapir: null,
  };

  componentDidMount() {
    this._isMounted = true;
    this.refreshPuntosZonas();
    this.refreshMediciones();
    this.refreshBateria();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filter !== prevState.filter) {
      this.refreshPuntosZonas();
      this.refreshMediciones();
      this.refreshBateria();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.intervalIDM); //para el intervalo de tiempo cuando se desmonta
    clearTimeout(this.intervalIDP);
    clearTimeout(this.intervalIDB);
  }

  isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
  }

  setStateFilter = (filter) => {
    this.setState({ filter });
  };

  refreshMediciones = () => {
    //traigo las mediciones de las ultimas 12 horas que sean de tipo 'posicion'
    //axios.get(`${api_root}Mediciones?tipo=2&horas=${this.state.filter.horas ? this.state.filter.horas: 12}
    axios
      .get(
        `${api_root}MapMediciones?tipo=2
      ${this.state.filter.tapir ? `&tapir=` + this.state.filter.tapir : ""}
			${this.state.filter.zona ? `&zona=` + this.state.filter.zona : ""}
      ${
        this.state.filter.fechaInicio
          ? `&fechaInicio=` + this.state.filter.fechaInicio.toString().trim()
          : ""
      }
      ${
        this.state.filter.fechaFin
          ? `&fechaFin=` + this.state.filter.fechaFin.toString().trim()
          : ""
      }`.replace(/\s+/g, ""),
        { headers: { Authorization: `Token ${this.context.token}` } }
      )
      .then((res) => {
        let t = res.data[0] ? res.data[0].Tapir.id : 0; //primer tapir
        let medicionesTapir = []; //arreglo para los puntos de un tapir
        const mediciones = []; //arreglo de arreglos 'medicionesTapir'
        res.data.map((medicion) => {
          const marcador = {
            latLng: JSON.parse(medicion.Valor),
            nombre: {
              id: medicion.Tapir.id, //t,
              //tapir: medicion.Tapir.Nombre,
              tapir: { ...medicion.Tapir },
              collar: medicion.Collar,
              fecha: formatDate(medicion.Fecha),
            },
          };
          if (medicion.Tapir.id !== t) {
            mediciones.push(medicionesTapir);
            t = medicion.Tapir.id;
            medicionesTapir = [];
          }
          return medicionesTapir.push(marcador);
        });
        mediciones.push(medicionesTapir);
        this._isMounted && this.setState({ mediciones });
        this.intervalIDM = setTimeout(
          this.refreshMediciones.bind(this),
          1800000
        ); //refrescar mediciones cada 30'
      });
  };

  /*
   *  Este metodo se define para traer el ultimo porcentaje de bateria de cada collar
   */

  refreshBateria = () => {
    //traigo las ultimas mediciones de cada collar, mediciones del tipo 1
    axios
      .get(`${api_root}Mediciones?tipo=1`, {
        headers: { Authorization: `Token ${this.context.token}` },
      })
      .then((res) => {
        const baterias = []; //arreglo de arreglos 'medicionesTapir'
        res.data.forEach((item) => {
          item.Fecha = formatDate(item.Fecha);
          baterias.push(item);
        });
        this._isMounted && this.setState({ baterias });
        this.intervalIDM = setTimeout(this.refreshBateria.bind(this), 1800000); //refrescar baterias cada 30'
      });
  };

  /*
   * Este método se define para traer las zonas con los puntos que las definen.
   */
  refreshPuntosZonas = () => {
    axios
      .get(
        `${api_root}ListZona_ZonasDelimitadas?${
          this.state.filter.zona ? `zona=` + this.state.filter.zona : ""
        }`,
        { headers: { Authorization: `Token ${this.context.token}` } }
      )
      .then((res) => {
        const puntosZonas = [];
        const cuadrados = [];
        const zonas = [];
        //recorro el arreglo obtenido de la API. Cada elemento de este arreglo es una zona
        res.data.map((element) => {
          const puntos = [];
          zonas.push({
            id: element.id,
            NombreZona: element.NombreZona,
            EsCobertura: element.EsCobertura,
            zonatapir_set: element.zonatapir_set,
          });
          //cada zona tiene un arreglo con los puntos que definen a la zona.Recorro cada punto.
          element.puntoszona_set.map((punto) => {
            //le doy formato al punto para que lo pueda leer el mapa. Luego lo guardo en un arreglo de puntos.
            return puntos.push({ lat: punto.Latitud, lng: punto.Longitud });
          });
          //guardo el arreglo de puntos en un arreglo de arreglos de puntos.
          cuadrados.push({ id: element.id, puntos: getSquare(puntos) });
          return puntosZonas.push({ id: element.id, puntos: puntos });
        });
        this._isMounted && this.setState({ cuadrados });
        this._isMounted && this.setState({ puntosZonas });
        this._isMounted && this.setState({ zonas });
        this.intervalIDP = setTimeout(
          this.refreshPuntosZonas.bind(this),
          600000
        ); //Refresca puntos zona cada 10'
      });
  };

  /*
   * Este método se define para poblar el componente ListGroup.
   */
  getItems = () => {
    return this.state.zonas.map((zona, index) => {
      return (
        <ListGroup.Item key={index}>
          <h6>
            <FontAwesomeIcon icon="vector-square" color={getColor(index)} />
            {" " + zona.NombreZona}{" "}
          </h6>
        </ListGroup.Item>
      );
    });
  };

  handleSelect = (selectedTab) => {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    this.setState({
      activeTab: selectedTab,
    });
  };

  changeMapTable = (showTable) => {
    //let table = this.state.table;
    // this.setState({
    //   table: !table
    // });
    this.setState({
      table: showTable,
    });
  };

  handleShowBaterias = () => {
    this.setState({ showBaterias: true });
  };

  handleCloseBaterias = () => {
    this.setState({ showBaterias: false });
  };

  customizeModal = (rows) => {
    return (
      <Table
        bsPrefix="table-wrapper-scroll-y my-custom-scrollbar"
        className="table table-bordered table-striped mb-0"
        responsive
      >
        <thead>
          <tr>
            <th>Collar</th>
            <th>Tapir</th>
            <th>Bateria</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  };

  render() {
    const rows = this.state.baterias.map((row, id) => {
      return (
        <tr key={id}>
          <td>{row.Collar.Nombre}</td>
          <td>{row.Tapir.Nombre}</td>
          <td>{row.Valor}%</td>
          <td>{row.Fecha}</td>
        </tr>
      );
    });
    const table = this.state.table;
    const a = () => {
      if (this._isMounted) {
        return (
          <LeafletMap
            zonas={this.state.puntosZonas}
            posiciones={this.state.mediciones}
            cuadrados={
              []
              //this.state.cuadrados}
            }
            detalle={(tapir) => {
              this.setState({ showDetalleTapir: true, selectedTapir: tapir });
            }}
          />
        );
      }
    };
    const b = () => {
      if (this._isMounted) {
        return (
          <TableMediciones
            posiciones={this.state.mediciones ? this.state.mediciones : []}
          />
        );
      }
    };

    return (
      <>
        <Col
          md={12}
          className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 mt-1 border-bottom"
        >
          <h1 className="h2">Monitoreo</h1>
          <ButtonToolbar className="mb-2 mb-md-0">
            <ButtonGroup className="mr-2">
              <Button
                variant="outline-secondary btn-sm"
                onClick={() =>
                  this.setState({ showFilterbar: !this.state.showFilterbar })
                }
              >
                Filtrar
              </Button>
              <Button
                variant="outline-secondary btn-sm"
                onClick={this.handleShowBaterias}
                style={{
                  display:
                    this.context.user !== null &&
                    this.context.user.groups !== undefined &&
                    this.context.user.groups[0] !== undefined &&
                    this.context.user.groups[0].Nombre === "patrocinador"
                      ? "none"
                      : "",
                }}
              >
                Baterías
              </Button>
            </ButtonGroup>
            <Dropdown alignRight>
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                Ver
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    this.changeMapTable(true);
                  }}
                >
                  Mapa
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    this.changeMapTable(false);
                  }}
                >
                  Tabla
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </ButtonToolbar>
        </Col>
        {this.state.showFilterbar ? (
          <Col md={12} className="">
            <Filterbar setFilter={this.setStateFilter} />
          </Col>
        ) : null}
        {
          // REFERENCIAS PARA MOBILE
          <Col
            style={{ maxHeight: "500px", overflowY: "scroll" }}
            className="d-block d-sm-block d-md-none"
            md={12}
          >
            <Navbar className="px-0" variant="light" bg="light" expand="md">
              <Navbar.Brand href="#">Referencias</Navbar.Brand>
              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="dropdown-toggle"
              >
                <span></span>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <MapReference zonas={this.state.zonas} />
              </Navbar.Collapse>
            </Navbar>
          </Col>
        }
        <Col
          className="d-none d-sm-none d-md-block pr-0"
          md={3}
          style={{ maxHeight: "500px", overflowY: "scroll" }}
        >
          <MapReference zonas={this.state.zonas} />
          {
            //  <MapReference2 />
          }
        </Col>
        {table ? <Col md={9}>{a()}</Col> : <Col md={9}>{b()}</Col>}
        <Modal
          show={this.state.showBaterias}
          onHide={this.handleCloseBaterias}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bateria de Collares:</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.customizeModal(rows)}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseBaterias}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showDetalleTapir}
          onHide={() => {
            this.setState({ showDetalleTapir: false });
          }}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Detalle del tapir</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} sm={6}>
                <ImagenAlbum
                  imagenes={
                    this.state.selectedTapir
                      ? this.state.selectedTapir.tapir.fotos_set
                      : null
                  }
                />
              </Col>
              <Col>
                <p className="h4">
                  {this.state.selectedTapir
                    ? this.state.selectedTapir.tapir.Nombre
                    : ""}
                  <br />
                </p>
                <p>
                  {this.state.selectedTapir ? (
                    <>
                      <FontAwesomeIcon icon={"birthday-cake"} />
                      {" " +
                        (this.state.selectedTapir.tapir.FechaNacimiento
                          ? formatDate(
                              this.state.selectedTapir.tapir.FechaNacimiento
                            ).slice(0, 10)
                          : "")}
                      <br />
                      {this.state.selectedTapir.tapir.Descripcion
                        ? this.state.selectedTapir.tapir.Descripcion
                        : ""}
                    </>
                  ) : (
                    ""
                  )}
                </p>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Album />
      </>
    );
  }
}

/*
 * Direccion del root de la API del backend.
 */
const api_root = "https://api.trackpir.volentini.com/Trackpir/api/"; //en produccion ip de la docker machine, en desarrollo localhost
const auth_root = "https://api.trackpir.volentini.com/Trackpir/password/";

/*
 * Centro del mapa
 */
const map_center = { lat: -26.7613, lng: -65.3234 };

/*
 * Arreglo de colores ordenados alfabpeticamente
 */
const colores = [
  "deeppink",
  "black",
  "blue",
  "brown",
  "dimgray",
  "green",
  "lime",
  "magenta",
  "orangered",
  "purple",
  "red",
  "salmon",
  "silver",
  "tan",
  "teal",
  "yellow",
  "turquoise",
];

/*
 * Devuelve un color del 'colores'
 */
const getColor = (index) => {
  return colores[index % colores.length];
};

const coloresIconos = [
  "black",
  "brown",
  "grey",
  "blue",
  "lightblue",
  "orange",
  "pink",
  "red",
  "green",
];
export const getColorIconos = (index) => {
  return coloresIconos[index % coloresIconos.length];
};

/*
 */
const getSquare = (puntos) => {
  const latitudes = [];
  const longitudes = [];
  if (puntos && puntos.constructor === Array && puntos.length !== 0) {
    puntos.forEach((punto) => {
      latitudes.push(Number(punto.lat));
      longitudes.push(Number(punto.lng));
    });
    const maxLat = Math.max(...latitudes);
    const minLat = Math.min(...latitudes);
    const maxLng = Math.max(...longitudes);
    const minLng = Math.min(...longitudes);
    return [
      { lat: minLat, lng: minLng },
      { lat: minLat, lng: maxLng },
      { lat: maxLat, lng: maxLng },
      { lat: maxLat, lng: minLng },
    ];
  } else {
    return [];
  }
};

/*
 * Exportaciones
 */
export { api_root, getColor, getSquare, auth_root, map_center };

import React from "react";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import { Formik } from "formik";
import { PropTypes } from "prop-types";

const HOY = new Date();

export class TapirForm extends React.Component {
  validate = (values) => {
    const errors = {};
    if (!values.Nombre) {
      errors.Nombre = "Campo requerido";
    }

    if (new Date(values.FechaNacimiento) > HOY) {
      errors.FechaNacimiento = "Fecha inválida";
    }

    return errors;
  };

  render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        validate={this.validate}
        onSubmit={this.props.onSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                id="Nombre"
                name="Nombre"
                {...formik.getFieldProps("Nombre")}
                isInvalid={formik.errors.Nombre && formik.touched.Nombre}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Nombre}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Fecha de nacimiento (opcional)</Form.Label>
              <Form.Control
                type="date"
                id="FechaNacimiento"
                name="FechaNacimiento"
                {...formik.getFieldProps("FechaNacimiento")}
                isInvalid={
                  formik.errors.FechaNacimiento &&
                  formik.touched.FechaNacimiento
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.FechaNacimiento}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Descripción (opcional)</Form.Label>
              <Form.Control
                as="textarea"
                rows="5"
                id="Descripcion"
                name="Descripcion"
                {...formik.getFieldProps("Descripcion")}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Subir foto (opcional)</Form.Label>
              <Form.Control
                type="file"
                rows="5"
                id="Foto"
                name="Foto"
                onChange={(event) => {
                  formik.setFieldValue("Foto", event.currentTarget.files[0]);
                }}
              />
            </Form.Group>

            <ButtonGroup>
              <Button className="mr-2" type="submit">
                Guardar
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

TapirForm.defaultProps = {
  initialValues: {
    Nombre: "",
    FechaNacimiento: undefined,
    Descripcion: "",
  },
  onSubmit: () => {},
  onCancel: () => {},
};

TapirForm.propTypes = {
  initialValues: PropTypes.shape({
    Nombre: PropTypes.string,
    FechaNacimiento: PropTypes.string,
    Descripcion: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

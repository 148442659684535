import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Col, Form, Container, Modal } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";
import { auth_root } from "./utils";

export class PasswordResetForm extends React.Component {
  state = {
    success: false,
    showModalSuccess: false,
    showModalError: false,
    msjErr: "",
    msjSuccess: "",
  };

  validate = (values) => {
    const errors = {};
    if (!values.newpassword1) {
      errors.newpassword1 = "Campo requerido";
    }
    if (!values.newpassword2) {
      errors.newpassword2 = "Campo requerido";
    }

    return errors;
  };

  handleOnSubmit = (values) => {
    //event.preventDefault();
    values.newpassword1 === values.newpassword2
      ? this.sendResetPassword(values)
      : this.setState({
          showModalError: true,
          msjErr: "Las contraseñas no coinciden",
        });
  };

  sendResetPassword = (values) => {
    const uid = this.props.match.params.uid;
    const token = this.props.match.params.token;
    const body = {
      new_password1: values.newpassword1,
      new_password2: values.newpassword2,
      uid: uid,
      token: token,
    };
    axios
      .post(`${auth_root}reset/confirm/`, body)
      .then((res) => {
        this.setState({ showModalSuccess: true });
        this.setState({ msjSuccess: "Reseteo de contraseña exitoso" });
      })
      .catch((err) => {
        this.setState({ showModalError: true });
        this.handleError(err.response.status);
      });
  };

  handleError = (err) => {
    switch (err) {
      case 400:
        this.setState({ msjErr: "Error en la contraseña" });
        break;
      case 500:
        this.setState({ msjErr: "Problemas en la red, pruebe mas tarde" });
        break;
      default:
        this.setState({
          msjErr: "Problemas en la red, comuniquese con el admin",
        });
        break;
    }
  };
  modalSuccess = () => {
    const msj = this.state.msjSuccess;
    return (
      <Modal.Dialog>
        <Modal.Header closeButton onClose={this.hideModalSuccess}>
          <Modal.Title>EXITO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{msj}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.hideModalSuccess}>
            Close
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    );
  };

  hideModalSuccess = () => {
    this.setState({ showModalSuccess: false, success: true });
  };

  modalError = () => {
    const msj = this.state.msjErr;
    return (
      <Modal.Dialog>
        <Modal.Header closeButton onClose={this.hideModalError}>
          <Modal.Title>ERROR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{msj}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.hideModalError}>
            Close
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    );
  };

  hideModalError = () => {
    this.setState({ showModalError: false });
  };

  render() {
    if (this.state.success) {
      return <Redirect to="/" />;
    }
    if (this.state.showModalError) {
      return this.modalError();
    }
    if (this.state.showModalSuccess) {
      return this.modalSuccess();
    }
    return (
      <Container align="center" className="my-3">
        <Col
          md={6}
          className="justify-content-center"
          style={{
            borderStyle: "solid",
            borderWidth: "2px",
            borderColor: "grey",
          }}
        >
          <div className="d-flex justify-content-center my-3">
            <h3>Resetear contraseña</h3>
          </div>
          <Formik
            initialValues={this.state}
            validate={this.validate}
            onSubmit={this.handleOnSubmit}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
                  <Form.Control
                    id="newpassword1"
                    type="password"
                    name="newpassword1"
                    placeholder="Nueva contraseña"
                    {...formik.getFieldProps("newpassword1")}
                    value={formik.values.newpassword1}
                    isInvalid={
                      formik.errors.newpassword1 && formik.touched.newpassword1
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.newpassword1}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="newpassword2"
                    type="password"
                    name="newpassword2"
                    placeholder="Confirmar nueva contraseña"
                    {...formik.getFieldProps("newpassword2")}
                    value={formik.values.newpassword2}
                    isInvalid={
                      formik.errors.newpassword2 && formik.touched.newpassword2
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.newpassword2}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button className="my-3 mr-2" variant="primary" type="submit">
                  Aceptar
                </Button>
                <Button className="my-3" variant="primary" href="/login">
                  Cancelar
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Container>
    );
  }
}

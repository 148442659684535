import React, { useContext } from "react";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../UserContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const IconButton = props => {
  const context = useContext(UserContext);

  //Los botones en los Admins tenian size="xs" en FontAwesomeIcon
  return (
    <OverlayTrigger
      placement={"top"}
      delay={200}
      overlay={
        <Tooltip style={{ display: props.tooltip ? "" : "none" }}>
          {props.tooltip}
        </Tooltip>
      }
    >
      <button
        name={props.name}
        onClick={event => {
          props.onClick(event);
        }}
        className="mr-2"
        style={{
          backgroundColor: "transparent",
          border: "none",
          padding: "0px 0px",
          display:
            !props.display &&
            context.user !== null &&
            context.user.groups !== undefined &&
            context.user.groups[0] !== undefined &&
            context.user.groups[0].Nombre === "restringido"
              ? "none"
              : ""
        }}
      >
        <FontAwesomeIcon
          icon={props.icon}
          color={props.color}
          cursor="pointer"
          fixedWidth
          style={{ pointerEvents: "none" }}
        />
      </button>
    </OverlayTrigger>
  );
};

IconButton.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func
};

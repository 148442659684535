import React from "react";
import { PropTypes } from "prop-types";
import { Button } from "react-bootstrap";
import { IconButton } from "../admin/IconButton";
import UniversalModal from "../commons/UniversalModal";
import DatosPerfilTabla from "./datosPerfil/DatosPerfilTabla";
import DatosPerfilForm from "./datosPerfil/DatosPerfilForm";
import ConfirmationForm from "../commons/ConfirmationForm";
import submitFuncProvider from "./datosPerfil/SubmitFuncProvider";
import useUniversalModal from "../commons/useUniversalModal";

/** Este componente renderiza el layout para la sección
 * de datos del perfil de un usuario.
 */
export default function DatosPerfil(props) {
  /** Para manejar el componente UniversalModal */
  const [show, showModal, hideModal] = useUniversalModal();

  const onSubmit = submitFuncProvider({
    urlUser: "User/",
    urlPerfil: "Perfil/",
    thenFunction: () => props.refreshData(),
  });

  return (
    <>
      <div style={{ display: "block" }} className={"h1"}>
        <p style={{ display: "inline-block" }} className={"mr-2"}>
          Datos del perfil
        </p>
        <IconButton
          display={true}
          tooltip="Editar"
          name="edit"
          icon="edit"
          onClick={showModal}
        />
        <Button
          name="desactivar"
          variant="outline-danger"
          type="submit"
          onClick={showModal}
        >
          Desactivar cuenta
        </Button>
      </div>

      {props.data ? <DatosPerfilTabla data={props.data} /> : "Cargando..."}

      <UniversalModal
        show={show === "edit"}
        onHide={hideModal}
        title={"Datos del perfil"}
      >
        <DatosPerfilForm
          initialValues={props.data}
          onSubmit={(values) => {
            hideModal();
            onSubmit(values);
          }}
        />
      </UniversalModal>

      <UniversalModal
        show={show === "desactivar"}
        onHide={hideModal}
        title={"Confirmación"}
      >
        <p>
          ¿Está seguro que desea desactivar el usuario? Al hacer esto, el
          usuario no tendrá acceso a la aplicación
        </p>
        <ConfirmationForm
          initialValues={{
            user: {
              id: props.data ? props.data.idUser : null,
              is_active: false,
            },
            perfil: null,
          }}
          onSubmit={(values) => {
            hideModal();
            onSubmit(values);
          }}
          onCancel={hideModal}
        />
      </UniversalModal>
    </>
  );
}

DatosPerfil.propTypes = {
  /* Los datos del perfil. Proporcionada por MultipleDataProveedor */
  data: PropTypes.object,

  /* Actualiza la información en la prop data. Proporcionada por MultipleDataProveedor */
  refreshData: PropTypes.func,
};

import React from "react";
import { PropTypes } from "prop-types";
import { Modal } from "react-bootstrap";

/** Este componente renderiza sus hijos en un Modal.
 * Acepta un máximo de dos hijos.
 */
export default function UniversalModal(props) {
  const childrens = React.Children.toArray(props.children);

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{childrens[0]}</Modal.Body>
      {childrens[1] ? <Modal.Footer>{childrens[1]}</Modal.Footer> : null}
    </Modal>
  );
}

UniversalModal.propTypes = {
  /** Objetos a renderizar en el Body o el Footer del modal */
  children: PropTypes.any.isRequired,
  /** Función que se ejecuta al hacer click en la "x" del modal.
   * Generalmente setea "show" en false.
   */
  onHide: PropTypes.func.isRequired,
  /** Variable que indica si mostrar el Modal */
  show: PropTypes.bool.isRequired,
  /** Título del modal */
  title: PropTypes.string,
};

import React from "react";
import { LoginForm } from "./LoginForm";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export class LoginPage extends React.Component {
  render() {
    return (
      <Col>
        <Row className="justify-content-center align-items-sm-center h-100 bg-light">
          <Col
            sm="auto"
            className="justify-content-center bg-white shadow p-4 p-sm-5"
          >
            <LoginForm />
          </Col>
        </Row>
      </Col>
    );
  }
}

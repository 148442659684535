import React from "react";
import { PropTypes } from "prop-types";
import { Table } from "react-bootstrap";

/** Este componente renderiza una tabla para mostrar
 * los datos de la cuenta de un usuario.
 */
export default function DatosPerfilTabla(props) {
  const { data } = props;

  return (
    <Table size={"sm"}>
      <tbody>
        <tr>
          <th className="text-right">{"Nombre"}</th>
          <td>{data.Nombre}</td>
        </tr>
        <tr>
          <th className="text-right">{"Apellido"}</th>
          <td>{data.Apellido}</td>
        </tr>
        <tr>
          <th className="text-right">{"Email"}</th>
          <td>{data.email}</td>
        </tr>
        <tr>
          <th className="text-right">{"Telefono"}</th>
          <td>{data.Telefono}</td>
        </tr>
        <tr>
          <th className="text-right">{"Username"}</th>
          <td>{data.username}</td>
        </tr>
        <tr>
          <th className="text-right">{"Contraseña"}</th>
          <td>{"****"}</td>
        </tr>
      </tbody>
    </Table>
  );
}

DatosPerfilTabla.propTypes = {
  /** Datos del usuario */
  data: PropTypes.shape({
    Apellido: PropTypes.string,
    email: PropTypes.string,
    Nombre: PropTypes.string,
    Telefono: PropTypes.string,
    username: PropTypes.string,
  }),
};

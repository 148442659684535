import React from "react";
import { Form, Button, ButtonGroup } from "react-bootstrap";
import { Formik } from "formik";
import { PropTypes } from "prop-types";

export class CollarForm extends React.Component {
  validate = (values) => {
    const errors = {};
    if (!values.Nombre) {
      errors.Nombre = "Campo requerido";
    }
    if (!values.MACAddress) {
      errors.MACAddress = "Campo requerido";
    }
    return errors;
  };

  render() {
    return (
      <Formik
        initialValues={this.props.initialValues}
        validate={this.validate}
        onSubmit={this.props.onSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                id="Nombre"
                name="Nombre"
                {...formik.getFieldProps("Nombre")}
                isInvalid={formik.errors.Nombre && formik.touched.Nombre}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Nombre}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>MAC Address</Form.Label>
              <Form.Control
                type="text"
                id="MACAddress"
                name="MACAddress"
                {...formik.getFieldProps("MACAddress")}
                isInvalid={
                  formik.errors.MACAddress && formik.touched.MACAddress
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.MACAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Observación (opcional)</Form.Label>
              <Form.Control
                as="textarea"
                id="Observacion"
                name="Observacion"
                {...formik.getFieldProps("Observacion")}
              />
            </Form.Group>

            <ButtonGroup>
              <Button type="submit">Guardar</Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

CollarForm.defaultProps = {
  initialValues: {
    Nombre: "",
    MACAddress: "",
    Observacion: "",
  },
  onSubmit: () => {},
  onCancel: () => {},
};

CollarForm.propTypes = {
  initialValues: PropTypes.shape({
    Nombre: PropTypes.string,
    MACAddress: PropTypes.string,
    Observacion: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

import React from "react";
import { TableAlarmas } from "./TableAlarmas";
import { Row, Container, Col, Button } from "react-bootstrap";
import axios from "axios";
import { Filterbar } from "./Filterbar";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { UserContext } from "./UserContext";
import { api_root } from "./utils";

/*
  Esta función recibe un string de fecha y
  devuelve un string con el siguiente formato:
  dd/mm/aaaa hh:MM
  Acepta dos tipos de fecha:
  - 2019-10-08T12:00:00Z
  - 2019-10-08
*/
export const formatDate = (date) => {
  if (date) {
    let aux;
    if (date.length > 10) {
      // Primer tipo de fecha
      aux = new Date(Date.parse(date));
    } else {
      //Segundo tipo de fecha
      aux = new Date(Date.parse(date + "T00:00:00"));
    }
    let day = aux.getDate();
    day = day < 10 ? "0" + day : day;
    let month = aux.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let hours = aux.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = aux.getMinutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    aux =
      day + "/" + month + "/" + aux.getFullYear() + " " + hours + ":" + minutes;
    return aux;
  }
  return null;
};

export class Alarmas extends React.Component {
  static contextType = UserContext;
  intervalID;
  state = {
    alertas: [],
    filter: {
      zona: 0,
      tapir: 0,
      horas: 0,
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filter !== prevState.filter) {
      this.refreshList();
    }
  }

  setStateFilter = (filter) => {
    this.setState({ filter });
  };

  refreshList = () => {
    axios
      .get(
        `${api_root}ListAlerta_Alertas?
      ${this.state.filter.tapir ? `&tapir=` + this.state.filter.tapir : ""}
      ${this.state.filter.zona ? `&zona=` + this.state.filter.zona : ""}`,
        { headers: { Authorization: `Token ${this.context.token}` } }
      )
      .then((res) => {
        const alertas = [];
        res.data.forEach((item) => {
          item.Fecha = formatDate(item.Fecha);
          alertas.push(item);
        });
        this.setState({ alertas });
        this.intervalIDM = setTimeout(this.refreshList.bind(this), 600000); //refresco lista cada 10'
      });
  };

  componentDidMount() {
    this.refreshList();
    fetch(`${api_root}ListAlerta_Alertas/`, {
      headers: { Authorization: `Token ${this.context.token}` },
    }).then(function (response) {
      return response.json();
    });
  }

  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }

  exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Reporte de Alarmas: ";
    const headers = [["Tipo de Alarma", "Tapir", "Fecha", "Zona"]];

    const data = this.state.alertas.map((a) => [
      a.TipoAlerta.TipoAlerta,
      a.Tapir.Nombre,
      a.Fecha,
      a.Tapir.zonatapir_set[0].ZonaDelimitada.NombreZona,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col md={12}>
              <Filterbar setFilter={this.setStateFilter} />
            </Col>
          </Row>
          <Row className="justify-content-center ">
            <TableAlarmas
              datos={this.state.alertas}
              refrest={this.refreshList}
            />
          </Row>
          <Row className="justify-content-end">
            <Button variant="danger" onClick={() => this.exportPDF()}>
              Exportar a PDF
            </Button>
          </Row>
        </Container>
      </>
    );
  }
}

import React, { Component } from "react";
import { Map, TileLayer, Marker, Polyline } from "react-leaflet";
import "./leaflet/leaflet.css";
import PropTypes from "prop-types";

export class MapClick extends Component {
  /*
   * Este método se define para ejecutarse cuando se hace click en un marcador.
   */
  markerClicked = (e) => {
    this.props.refreshMarcadores({ lat: e.latlng.lat, lng: e.latlng.lng });
  };

  /*
   * Este método se define para ejecutarse cuando se hace click en el mapa.
   */
  mapClicked = (e) => {
    this.props.refreshMarcadores({ lat: e.latlng.lat, lng: e.latlng.lng });
  };

  /*
   * Este método se define para dibujar un Polyline en el mapa.
   */
  drawPolyline = (puntos, index) => {
    // Controlo que 'puntos' esté definido, que sea un arreglo y que no esté vacío.
    if (puntos && puntos.constructor === Array && puntos.length !== 0) {
      return <Polyline key={index} positions={puntos} />;
    } else {
      return null;
    }
  };

  /*
   * Este método se define para dibujar marcadores en el mapa.
   */
  drawMarkers = (marcadores) => {
    marcadores.map((position, idx) => {
      if (idx) {
        return <Marker key={`marker-${idx}`} position={position}></Marker>;
      } else {
        return (
          <Marker
            key={`marker-${idx}`}
            position={position}
            onClick={this.mapClicked}
          ></Marker>
        );
      }
    });
  };

  componentDidMount() {
    // Esto es para que una vez que el modal se renderizo, espere 300ms y renderice el mapa
    // De lo contrario, el mapa no renderiza bien {bug de Leaflet Map}
    const map = this.refs.mapRef.leafletElement;
    setTimeout(() => {
      map.invalidateSize(false);
    }, 300);
  }

  render() {
    return (
      <div>
        <Map
          center={this.props.center}
          zoom={this.props.zoom}
          onClick={this.mapClicked}
          style={{ width: "100%", height: "500px" }}
          ref="mapRef"
          maxBounds={[
            [-26.077, -66.368],
            [-28.067, -66.368],
            [-28.067, -64.407],
            [-26.077, -64.407],
          ]}
          minZoom={9}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
          />
          {this.props.historial[this.props.step].map((position, idx) => {
            if (idx) {
              return (
                <Marker key={`marker-${idx}`} position={position}></Marker>
              );
            } else {
              return (
                <Marker
                  key={`marker-${idx}`}
                  position={position}
                  onClick={this.mapClicked}
                ></Marker>
              );
            }
          })}
          {this.drawPolyline(this.props.historial[this.props.step])}
        </Map>
      </div>
    );
  }
}

MapClick.propTypes = {
  refreshMarcadores: PropTypes.func,
  center: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  zoom: PropTypes.number,
  historial: PropTypes.array,
  step: PropTypes.number,
};

import React from "react";
import { Table } from "react-bootstrap";
import { PropTypes } from "prop-types";
import { IconButton } from "../IconButton";

/** Este componente renderiza una tabla para mostrar
 * los usuarios del sistema.
 */
export default function UsuariosTabla(props) {
  const { data } = props;

  /** Genero las filas de la tabla */
  const usuarios = data.map((usuario) => {
    const onClick = (event) => {
      props.onActionClick(event, usuario);
    };

    return (
      <tr key={usuario.id}>
        <td>{usuario.Nombre}</td>
        <td>{usuario.Apellido}</td>
        <td>{usuario.User.groups[0] ? usuario.User.groups[0].Nombre : ""}</td>
        <td>
          <IconButton
            tooltip="Configuración de alertas"
            name="alertas"
            icon="bell"
            onClick={onClick}
          />
          <IconButton
            tooltip="Cambiar permisos"
            name="permisos"
            icon="user-shield"
            onClick={onClick}
          />
          {usuario.User.is_active ? (
            <IconButton
              tooltip="Prohibir acceso"
              name="desactivar"
              icon="lock"
              onClick={onClick}
              color={"red"}
            />
          ) : (
            <IconButton
              tooltip="Conceder acceso"
              name="activar"
              icon="lock-open"
              onClick={onClick}
              color={"green"}
            />
          )}
        </td>
      </tr>
    );
  });

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Apellido</th>
          <th>Permisos</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>{usuarios}</tbody>
    </Table>
  );
}

UsuariosTabla.propTypes = {
  /* Informacion a ser mostrada en la tabla.*/
  data: PropTypes.array,

  /** Función que se ejecuta al hacer click en algún botón
   * de la columna Acciones.
   */
  onActionClick: PropTypes.func,
};

import React from "react";
import { PropTypes } from "prop-types";
import { Button } from "react-bootstrap";

/** Este componente renderiza un form para que el usuario confirme
 * una acción.
 */
export default function ConfirmationForm(props) {
  /** Función que se ejecta al hacer click en el botón "Si" del Form. */
  function onSubmit() {
    props.onSubmit(props.initialValues);
  }

  return (
    <>
      <Button variant="danger" onClick={onSubmit}>
        Si
      </Button>
      <Button
        variant="secondary"
        onClick={() => {
          if (props.onCancel) {
            props.onCancel();
          }
        }}
      >
        Cancelar
      </Button>
    </>
  );
}

ConfirmationForm.propTypes = {
  /** <optional> Función que se ejecuta al hacer click en "Cancelar"*/
  onCancel: PropTypes.func,

  /* Valores que serán mandados en la request */
  initialValues: PropTypes.object,

  /** Función que realiza una request con los datos.*/
  onSubmit: PropTypes.func,
};

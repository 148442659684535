import React from "react";
import { PropTypes } from "prop-types";
import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

/** Este componente renderiza un form donde se puede
 * editar o cargar datos de la cuenta de un usuario.
 */
export default function DatosPerfilForm(props) {
  /** Acomoda los datos que serán enviados en la request */
  function arrengeData(values) {
    if (values.idUser || values.idPerfil) {
      return arrengeDataUpdate(values);
    }
    return arrengeDataCreate(values);
  }

  /** Acomoda los datos si se va a crear una cuenta */
  function arrengeDataCreate(values) {
    return {
      username: values.username,
      email: values.email,
      password: values.password,
      Nombre: values.Nombre,
      Apellido: values.Apellido,
      Telefono: values.Telefono,
      Lunes: false,
      Martes: false,
      Miercoles: false,
      Jueves: false,
      Viernes: false,
      Sabado: false,
      Domingo: false,
      HoraDesde: "00:00",
      HoraHasta: "00:00",
    };
  }

  /** Acomoda los datos si se va a actualizar una cuenta */
  function arrengeDataUpdate(values) {
    const { initialValues } = props;
    let user = null;
    let perfil = null;

    if (
      initialValues.username !== values.username ||
      initialValues.email !== values.email
    ) {
      user = {
        id: values.idUser,
        username: values.username,
        email: values.email,
      };
    }

    if (
      initialValues.Nombre !== values.Nombre ||
      initialValues.Apellido !== values.Apellido ||
      initialValues.Telefono !== values.Telefono
    ) {
      perfil = {
        id: values.idPerfil,
        Nombre: values.Nombre,
        Apellido: values.Apellido,
        Telefono: values.Telefono,
        User: values.User,
        HoraDesde: values.HoraDesde,
        HoraHasta: values.HoraHasta,
        Lunes: values.Lunes,
        Martes: values.Martes,
        Miercoles: values.Miercoles,
        Jueves: values.Jueves,
        Viernes: values.Viernes,
        Sabado: values.Sabado,
        Domingo: values.Domingo,
      };
    }
    return {
      user: user,
      perfil: perfil,
    };
  }

  /** Valida los datos cargados en el formulario */
  function validate(values) {
    const errors = {};
    if (!values.Nombre) {
      errors.Nombre = "Campo requerido";
    }
    if (!values.Apellido) {
      errors.Apellido = "Campo requerido";
    }
    if (!values.email) {
      errors.email = "Campo requerido";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Email invalido";
    }
    if (!props.initialValues.idUser) {
      if (!values.username) {
        errors.username = "Campo requerido";
      }
      if (!values.password) {
        errors.password = "Campo requerido";
      }
      if (values.password.length < 4) {
        errors.password = "Contraseña muy corta";
      }
      if (!values.confirmpassword) {
        errors.confirmpassword = "Campo requerido";
      }
    }
    return errors;
  }

  /** Función que se ejecuta al hacer click en el botón "Guardar" (o equivalente) */
  function onSubmit(values) {
    props.onSubmit(arrengeData(values));
  }

  const { initialValues } = props;

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Form.Label className="d-flex justify-content-start">
              Nombre:
            </Form.Label>
            <Form.Control
              type="text"
              id="Nombre"
              name="Nombre"
              placeholder="Nombre"
              {...formik.getFieldProps("Nombre")}
              isInvalid={formik.errors.Nombre && formik.touched.Nombre}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Nombre}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="d-flex justify-content-start">
              Apellido:
            </Form.Label>
            <Form.Control
              id="Apellido"
              type="text"
              name="Apellido"
              placeholder="Apellido"
              {...formik.getFieldProps("Apellido")}
              isInvalid={formik.errors.Apellido && formik.touched.Apellido}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Apellido}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="d-flex justify-content-start">
              Email:
            </Form.Label>
            <Form.Control
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              {...formik.getFieldProps("email")}
              isInvalid={formik.errors.email && formik.touched.email}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="d-flex justify-content-start">
              Número celular (opcional):
            </Form.Label>
            <Form.Control
              id="Telefono"
              type="Number"
              name="Telefono"
              placeholder="Numero de celular"
              {...formik.getFieldProps("Telefono")}
              isInvalid={formik.errors.Telefono && formik.touched.Telefono}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Telefono}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="d-flex justify-content-start">
              Username:
            </Form.Label>
            <Form.Control
              id="username"
              type="text"
              name="username"
              placeholder="Nombre de usuario"
              {...formik.getFieldProps("username")}
              isInvalid={formik.errors.username && formik.touched.username}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.username}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label className="d-flex justify-content-start">
              Contraseña:
            </Form.Label>
            {initialValues.idUser ? (
              <div className="d-flex justify-content-start">
                <Button
                  variant="outline-secondary"
                  type="submit"
                  as={Link}
                  to="/perfil/edit"
                >
                  Cambiar contraseña
                </Button>
              </div>
            ) : (
              <>
                <Form.Control
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  {...formik.getFieldProps("password")}
                  isInvalid={formik.errors.password && formik.touched.password}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
              </>
            )}
          </Form.Group>

          {initialValues.idUser ? null : (
            <Form.Group>
              <Form.Label className="d-flex justify-content-start">
                Confirmar contraseña:
              </Form.Label>
              <Form.Control
                id="confirmpassword"
                type="password"
                name="confirmpassword"
                placeholder="Confirmar contraseña"
                {...formik.getFieldProps("confirmpassword")}
                isInvalid={
                  formik.errors.confirmpassword &&
                  formik.touched.confirmpassword
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.confirmpassword}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Button className="my-3" variant="primary" type="submit">
            {initialValues.idUser ? "Guardar" : "Registrarme"}
          </Button>
        </Form>
      )}
    </Formik>
  );
}

DatosPerfilForm.defaultProps = {
  initialValues: {
    Nombre: "",
    Apellido: "",
    email: "",
    Telefono: "",
    username: "",
    password: "",
    confirmpassword: "",
  },
};

DatosPerfilForm.propTypes = {
  /**<optional> Los valores iniciales de los campos del fromulario */
  initialValues: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    Nombre: PropTypes.string,
    Apellido: PropTypes.string,
    Telefono: PropTypes.string,
    User: PropTypes.number,
    HoraDesde: PropTypes.string,
    HoraHasta: PropTypes.string,
    Lunes: PropTypes.bool,
    Martes: PropTypes.bool,
    Miercoles: PropTypes.bool,
    Jueves: PropTypes.bool,
    Viernes: PropTypes.bool,
    Sabado: PropTypes.bool,
    Domingo: PropTypes.bool,
  }),
  /** Función que se ejecuta al hacer click en el botón guardar*/
  onSubmit: PropTypes.func,
};

import React from "react";
import { PropTypes } from "prop-types";
import { Table } from "react-bootstrap";
import { IconButton } from "../../admin/IconButton";

/** Este componente renderiza una tabla para mostrar
 * los tapires de la configuración de alertas de un usuario.
 */
export default function ConfigTapiresTabla(props) {
  const { data } = props;

  /** Genero las filas de la tabla */
  const tapires = data.map((tapir) => {
    const tiposDeAlertas = tapir.alertasusuarios_set.map((subitem) => {
      return <li key={subitem.id}>{subitem.TipoAlerta.TipoAlerta}</li>;
    });

    const onClick = (event) => {
      props.onActionClick(event, tapir);
    };

    return (
      <tr key={tapir.id}>
        <td>{tapir.Nombre}</td>
        <td>{tiposDeAlertas}</td>
        <td>
          <IconButton
            tooltip="Agregar tipo de alerta"
            name="agregarTipo"
            icon="edit"
            color="green"
            onClick={onClick}
          />
          <IconButton
            tooltip="Eliminar tipo de alerta"
            name="eliminarTipo"
            icon="edit"
            color="red"
            onClick={onClick}
          />
          <IconButton
            tooltip="Borrar tapir"
            name="borrarTapir"
            icon="trash-alt"
            onClick={onClick}
          />
        </td>
      </tr>
    );
  });

  return (
    <Table>
      <thead>
        <tr>
          <th>Tapir</th>
          <th>Tipos de alertas</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>{tapires}</tbody>
    </Table>
  );
}

ConfigTapiresTabla.propTypes = {
  /* Información a ser mostrada en la tabla.*/
  data: PropTypes.array,

  /** Función que se ejecuta al hacer click en algún botón
   * de la columna Acciones.
   */
  onActionClick: PropTypes.func,
};

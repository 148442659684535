import React from "react";
import { PropTypes } from "prop-types";
import SelectForm from "../../../commons/SelectForm";

/** Este componente renderiza un formulario para agregar un Tipo de alerta
 * a la configuración de alertas de un usuario.
 */
export default function AgregarTipoForm(props) {
  /** Acomoda los datos de cierta manera. */
  function arrengeData(values) {
    return {
      User: values.User,
      TipoAlerta: values.elegido,
      FechaDesde: new Date().toISOString().slice(0, 10),
      FechaHasta: null,
      Tapir: values.Tapir.id,
    };
  }

  /** Función que se ejecta al hacer click
   * en el botón "Guardar" (o equivalente) del Form.
   */
  function onSubmit(values) {
    props.onSubmit(arrengeData(values));
  }

  const { data } = props;

  // Opciones para llenar el menú desplegable (SelectForm).
  const options = data.map((option) => {
    return (
      <option key={option.id} value={option.id}>
        {option.TipoAlerta}
      </option>
    );
  });

  return (
    <SelectForm
      options={options}
      initialValues={props.initialValues}
      onSubmit={onSubmit}
    />
  );
}

AgregarTipoForm.propTypes = {
  /** Las opciones que se mostrarán en el menú desplegable.*/
  data: PropTypes.array,

  /** Requerido por Formik */
  initialValues: PropTypes.shape({
    /* Elegido por defecto en el menú desplegable. Valor recomendado: "0" */
    elegido: PropTypes.string,
    /* Tapir al que se agregará el tipo */
    Tapir: PropTypes.object,
    /* user al que se agregará el tipo */
    User: PropTypes.number,
  }),

  /** Función que realiza el POST con los datos.*/
  onSubmit: PropTypes.func,
};

AgregarTipoForm.defaultProps = {
  initialValues: {
    elegido: "0",
  },
};

import React from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import { UserContext } from "./UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate } from "./Alarmas";
import axios from "axios";
import "./album.css";
import { api_root } from "./utils";
import { ImagenAlbum } from "./ImagenAlbum";

export class Album extends React.Component {
  static contextType = UserContext;

  state = {
    tapires: [],
    index: 0,
  };

  componentDidMount() {
    axios
      .get(`${api_root}ListTapir/`, {
        headers: { Authorization: `Token ${this.context.token}` },
      })
      .then((res) => {
        this.setState({ tapires: res.data });
      })
      .catch((err) => console.error(err));
    window.addEventListener("resize", () => {
      this.setState({ index: 0 });
    });
  }

  partirArreglo = (arreglo) => {
    let nuevoArreglo = [];
    let cantidad = 4;

    if (window.innerWidth <= 992) {
      cantidad = 3;
    }
    if (window.innerWidth <= 768) {
      cantidad = 2;
    }
    if (window.innerWidth <= 576) {
      cantidad = 1;
    }
    let inicio = 0;
    let fin = cantidad;
    let i = 0;
    while (fin < this.state.tapires.length) {
      nuevoArreglo[i] = this.state.tapires.slice(inicio, fin);
      i++;
      inicio += cantidad;
      fin += cantidad;
    }
    nuevoArreglo[i] = this.state.tapires.slice(inicio);
    return nuevoArreglo;
  };

  handleSelect = (selectedIndex, e) => {
    this.setState({ index: selectedIndex });
  };

  render() {
    return (
      <>
        <Col
          md={12}
          className=" d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mt-1 border-bottom"
        >
          <h1 className="h2">Tapires</h1>
        </Col>

        <div className="py-5" style={{ width: "100%" }}>
          <Carousel
            activeIndex={this.state.index}
            interval={false}
            wrap={true}
            onSelect={this.handleSelect}
          >
            {this.partirArreglo(this.state.tapires).map((tapires, i) => (
              <Carousel.Item key={i}>
                <Container>
                  <Row>
                    {tapires &&
                    tapires.constructor === Array &&
                    tapires.length > 0
                      ? tapires.map((item, i) => {
                          return (
                            <Col xs={12} sm={6} md={4} lg={3} key={i}>
                              <Card
                                className="mb-4"
                                style={{ height: "410px" }}
                              >
                                <ImagenAlbum imagenes={item.fotos_set} />
                                <Card.Body>
                                  <Card.Title>{item.Nombre}</Card.Title>
                                  <Card.Text>
                                    <FontAwesomeIcon icon={"birthday-cake"} />
                                    {" " +
                                      (item.FechaNacimiento
                                        ? formatDate(
                                            item.FechaNacimiento
                                          ).slice(0, 10)
                                        : "")}
                                    <br />
                                    {item.Descripcion}
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })
                      : null}
                  </Row>
                </Container>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </>
    );
  }
}

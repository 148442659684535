import React from "react";
import { PropTypes } from "prop-types";
import { ButtonGroup, Button, Form } from "react-bootstrap";
import { Formik } from "formik";

export class SelectForm extends React.Component {
  validate = values => {
    const errors = {};
    if (!values.elegido) {
      errors.elegido = "Campo requerido";
    }
    return errors;
  };

  render() {
    const options = this.props.options.map(option => {
      return (
        <option key={option.id} value={option.id}>
          {option.Nombre}
        </option>
      );
    });

    return (
      <Formik
        initialValues={this.props.initialValues}
        validate={this.validate}
        onSubmit={this.props.onSubmit}
      >
        {formik => (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Control
                as="select"
                id="elegido"
                name="elegido"
                {...formik.getFieldProps("elegido")}
                isInvalid={formik.errors.elegido && formik.touched.elegido}
              >
                <option value={"0"}>Elegir opción</option>
                {options}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.elegido}
              </Form.Control.Feedback>
            </Form.Group>
            <ButtonGroup>
              <Button className="mr-2" type="submit">
                Guardar
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </Formik>
    );
  }
}

SelectForm.propTypes = {
  options: PropTypes.array,
  initialValues: PropTypes.shape({
    elegido: PropTypes.string
  }),
  onSubmit: PropTypes.func
};

SelectForm.defaultProps = {
  options: [],
  initialValues: {
    elegido: "0"
  },
  onSubmit: () => {}
};

import React from "react";
import { MapClick } from "../../MapClick";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import "../../my.css";
import { map_center } from "../../utils";
import PropTypes from "prop-types";

/*
 * Estado de PuntosInput
 *   marcadores: arreglo para almacenar los puntos del mapa donde el usuario haya hecho click
 *   historial: arreglo para almacenar todas las variaciones del arreglo marcadores
 *   para implementar la funcionalidad "deshacer".
 *   step: entero para moverme por historial.
 */
export class PuntosInput extends React.Component {
  state = {
    idZona: this.props.initialValues.id,
    marcadores: [],
    historial: [[]],
    step: 0,
    showMessage: false,
  };

  /*
   * Este método se define para ser pasado como props
   * del componente que contiene el mapa (MapClick).
   */
  setStateMarcadores = (marcador) => {
    this.setState((state) => {
      const step = this.state.step + 1; //avanzo al step siguiente
      const marcadores = [...state.historial[step - 1], marcador]; //copio los marcadores del step anterior y agrego el marcador nuevo.
      const historial = [...state.historial.slice(0, step), marcadores]; //copio el historial anterior y agrego el nuevo elemento.
      return { marcadores, historial, step };
    });
  };

  /*
   * Este método se define para implementar la funcionalidad deshacer
   */
  handleDeshacer = () => {
    const step = this.state.step;
    const anterior = step - 1;
    if (step) {
      this.setState({ step: anterior });
    }
  };

  /*
   * Este método se define para implementar la funcionalidad adelante
   */
  handleAdelante = () => {
    const step = this.state.step;
    const siguiente = step + 1;
    if (siguiente < this.state.historial.length) {
      this.setState({ step: siguiente });
    }
  };

  /*
   * Este método se define para ser llamado al hacer click en el botón 'Zona Lista'.
   */
  devolverPuntos = () => {
    if (this.state.historial[this.state.step].length <= 2) {
      this.setState({ showMessage: true });
    } else {
      const marcadores = this.state.historial[this.state.step];
      const puntos = {
        ZonaDelimitada: this.state.idZona,
        marcadores: [],
      };
      puntos.marcadores = [...marcadores];
      this.props.onSubmit(puntos);
    }
  };

  render() {
    const marcadores = this.state.historial[this.state.step];
    return (
      <Row>
        <Col xs={2} md={2}>
          <Button className="mr-2" onClick={this.handleDeshacer}>
            {"<-"}
          </Button>
          <Button className="mr-2" onClick={this.handleAdelante}>
            {"->"}
          </Button>
          <ListGroup bsPrefix="points-list-group">
            {marcadores.map((marcador, index) => {
              return (
                <ListGroup.Item key={index}>
                  {"lat: " +
                    Number(marcador.lat.toFixed(4)) +
                    "\nlng: " +
                    Number(marcador.lng.toFixed(4))}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
        <Col xs={10} md={10}>
          <MapClick
            zoom={13.5}
            center={map_center}
            refreshMarcadores={this.setStateMarcadores}
            marcadores={this.state.marcadores}
            historial={this.state.historial}
            step={this.state.step}
          />
        </Col>
        <Col>
          <Button className="mr-2 mt-2 d-inline" onClick={this.devolverPuntos}>
            {"Zona Lista"}
          </Button>
          {this.state.showMessage ? (
            <p className="text-danger d-inline">Zona inválida</p>
          ) : (
            ""
          )}
        </Col>
      </Row>
    );
  }
}

PuntosInput.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

import { useState } from "react";
import axios from "axios";

/**
 * Contiene la lógica para realizar un POST o PUT según corresponda.
 * Si los datos recibidos cuentan con un campo id, realiza un PUT.
 */
export default function useSubmit(props) {
  const [status, setStatus] = useState(null);

  /** Determina si hace un POST o PUT y envia los datos.*/
  function onSubmit(data) {
    let method = "post";
    let url = props.urlSubmit;
    if (data.id) {
      url = url + String(data.id) + "/";
      method = "put";
    }
    axios
      .request({
        url,
        method,
        data,
      })
      .then((res) => {
        setStatus(res.status);
        if (props.thenFunction) {
          props.thenFunction(res);
        }
      })
      .catch((err) => {
        if (err.response) {
          setStatus(err.response.status);
        } else {
          setStatus("failed");
        }
        if (props.catchFunction) {
          props.catchFunction(err);
        }
        console.error(err);
      });
  }

  return [status, onSubmit];
}

import axios from "axios";

/** Devuelve una función para mandar multiples reuqest*/
export default function multipleSubmitProveedor(props) {
  /** Determina si hace un POST o PUT y envia los datos.
   * data: un arreglo, realiza una peticion para cada item
   */
  function onSubmit(data) {
    data.forEach((item, i) => {
      let method = "post";
      let url = props.urlSubmit;
      if (item.id) {
        url = url + String(item.id) + "/";
        method = "put";
      }
      axios
        .request({
          url,
          method,
          data: item,
        })
        .then((res) => {
          if (props.thenFunction) {
            props.thenFunction(res);
          }
        })
        .catch((err) => {
          if (props.catchFunction) {
            props.catchFunction(err);
          }
          console.error(err);
        });
    });
  }

  return onSubmit;
}

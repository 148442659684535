import React from "react";
import { Table } from "react-bootstrap";
import { IconButton } from "../IconButton";
import PropTypes from "prop-types";

export class TapirTable extends React.Component {
  render() {
    const rows = this.props.values.map((tapir) => {
      const onClick = (event) => {
        event.preventDefault();
        this.props.setSelected(tapir);
        this.props.showModal(event);
      };
      const propsSeguir = {};
      let collar = "";
      if (tapir.tapirescollares_set && tapir.tapirescollares_set.length) {
        collar = tapir.tapirescollares_set[0].Collar.Nombre;
        propsSeguir.name = "dejarDeSeguir";
        propsSeguir.color = "red";
        propsSeguir.tooltip = "Dejar de seguir";
      } else {
        propsSeguir.name = "seguirTapir";
        propsSeguir.color = "green";
        propsSeguir.tooltip = "Seguir tapir";
      }

      return (
        <tr key={tapir.id}>
          <td>{tapir.Nombre}</td>
          <td>{tapir.FechaNacimiento}</td>
          <td className="text-break">{tapir.Descripcion}</td>
          <td>{collar}</td>
          <td>
            <IconButton
              tooltip={propsSeguir.tooltip}
              name={propsSeguir.name}
              icon="circle-notch"
              color={propsSeguir.color}
              onClick={onClick}
            />
            <IconButton
              tooltip={"Editar"}
              name="crearEditar"
              icon="edit"
              onClick={onClick}
            />
            <IconButton
              tooltip={"Borrar"}
              name="borrarTapir"
              icon="trash-alt"
              onClick={onClick}
            />
          </td>
        </tr>
      );
    });

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Fecha de nacimiento</th>
            <th style={{ width: "400px" }}>Descripción</th>
            <th>Collar</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  }
}

TapirTable.propTypes = {
  values: PropTypes.array,
  setSelected: PropTypes.func,
  showModal: PropTypes.func,
};

TapirTable.defaultProps = {
  //values: cada elemento del arreglo es un tapir.
  values: [
    {
      id: "defaultId",
      Nombre: "",
      FechaNacimiento: "",
      Descripcion: "",
      tapirescollares_set: [],
    },
  ],
  setSelected: () => {},
  showModal: () => {},
};

import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Col } from "react-bootstrap";
import UsuariosTabla from "./UsuariosTabla";
import UniversalModal from "../../commons/UniversalModal";
import useUniversalModal from "../../commons/useUniversalModal";
import ConfirmationForm from "../../commons/ConfirmationForm";
import submitFuncProvider from "../../perfil/datosPerfil/SubmitFuncProvider";
import PermisosForm from "./PermisosForm";
import useSubmit from "../../commons/useSubmit";
import ConfigHorario from "../../perfil/ConfigHorario";
import ConfigTapires from "../../perfil/ConfigTapires";
import { IconButton } from "../IconButton";
import useDataProveedor from "../../commons/useDataProveedor";

/** Componente que renderiza el layout
 * de la sección de administración de usuarios.
 */
export default function UsuariosAdmin(props) {
  const [selected, setSelected] = useState(null);

  const [show, showModal, hideModal] = useUniversalModal();

  /** Se traen los datos para UsuariosTabla */
  const [data, refreshData] = useDataProveedor("PerfilPermisos/");

  /** Se traen datos para el componente ConfigTapires */
  const [tapires, refreshTapires] = useDataProveedor(
    `UsuarioAdmin/?user=${selected ? selected.User.id : "0"}`
  );

  /** Se traen datos para el componente ConfigHorario */
  const [perfil, refreshPerfil] = useDataProveedor(
    `Perfil/?User=${selected ? selected.User.id : "0"}`
  );

  /** Se traen datos para el componente PermisosForm */
  const [permisos, refreshPermisos] = useDataProveedor("permisos/");

  const [statusSubmitPermisos, onSubmitPermisos] = useSubmit({
    urlSubmit: "permisos/",
    thenFunction: () => {
      refreshData();
      refreshPermisos();
    },
  });

  const onSubmitActivarDesactivar = submitFuncProvider({
    urlUser: "User/",
    urlPerfil: "Perfil/",
    thenFunction: () => {
      refreshData();
      refreshPermisos();
    },
  });

  if (show === "alertas") {
    return (
      <>
        <div style={{ display: "block" }} className={"h1"}>
          <IconButton
            tooltip="Atrás"
            name={null}
            icon="arrow-circle-left"
            onClick={(event) => {
              showModal(event);
              setSelected(null);
            }}
          />
          <p style={{ display: "inline-block" }} className={"mr-2"}>
            {selected ? selected.Apellido + ", " + selected.Nombre : ""}
          </p>
        </div>
        <Col>
          <ConfigHorario
            data={
              perfil.data && perfil.data.length !== 0
                ? { ...perfil.data[0] }
                : null
            }
            refreshData={refreshPerfil}
          />
        </Col>
        <Col>
          <ConfigTapires
            user={selected ? selected.User.id : ""}
            data={tapires.data ? tapires.data : null}
            refreshData={refreshTapires}
          />
        </Col>
      </>
    );
  }

  return (
    <>
      <div style={{ display: "block" }} className={"h1"}>
        <p style={{ display: "inline-block" }} className={"mr-2"}>
          Usuarios
        </p>
      </div>

      <UsuariosTabla
        data={data.data ? data.data : []}
        onActionClick={(event, tapir) => {
          showModal(event);
          setSelected(tapir);
        }}
      />

      <UniversalModal
        show={show === "permisos"}
        onHide={hideModal}
        title={"Datos del perfil"}
      >
        <PermisosForm
          initialValues={{
            elegido: "0",
            user: selected ? selected.User.id : null,
          }}
          data={permisos.data}
          onSubmit={(values) => {
            hideModal();
            if (Number(values.elegido) !== 0) {
              onSubmitPermisos(values);
            }
          }}
        />
      </UniversalModal>

      <UniversalModal
        show={show === "desactivar"}
        onHide={hideModal}
        title={"Datos del perfil"}
      >
        <p>
          ¿Está seguro que desea desactivar el usuario? Al hacer esto, el
          usuario no tendrá acceso a la aplicación.
        </p>
        <ConfirmationForm
          initialValues={{
            user: {
              id: selected ? selected.User.id : null,
              is_active: false,
            },
            perfil: null,
          }}
          onSubmit={(values) => {
            hideModal();
            onSubmitActivarDesactivar(values);
          }}
          onCancel={hideModal}
        />
      </UniversalModal>

      <UniversalModal
        show={show === "activar"}
        onHide={hideModal}
        title={"Datos del perfil"}
      >
        <p>
          ¿Está seguro que desea activar el usuario? Al hacer esto, el usuario
          tendrá acceso a la aplicación.
        </p>
        <ConfirmationForm
          initialValues={{
            user: {
              id: selected ? selected.User.id : null,
              is_active: true,
            },
            perfil: null,
          }}
          onSubmit={(values) => {
            hideModal();
            onSubmitActivarDesactivar(values);
          }}
          onCancel={hideModal}
        />
      </UniversalModal>
    </>
  );
}

UsuariosAdmin.propTypes = {
  /** Información a ser mostrada en la tabla.*/
  data: PropTypes.array,

  /** Función para actualizar data */
  refreshData: PropTypes.func,
};

import React from "react";
import { Col } from "react-bootstrap";
import { api_root } from "./utils.js";
import axios from "axios";
import { UserContext } from "./UserContext";

export class Page404 extends React.Component {
  _isMounted = false;

  static contextType = UserContext;

  state = {
    imagen: null,
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    axios
      .get(`${api_root}Imagenes/1/`, {
        headers: {
          Authorization: `${
            this.context.token ? `Token ${this.context.token}` : ""
          }`,
        },
      })
      .then((res) => {
        this._isMounted &&
          this.setState({
            imagen: "data:image/*;base64," + res.data,
          });
      });
  }

  render() {
    if (this.state.imagen == null) {
      return "cargando";
    } else {
      return (
        <>
          <Col className="p-0">
            <div
              className="d-flex justify-content-center align-items-center bg-light"
              style={{ width: "100%", height: "100vh" }}
            >
              <img
                id={"image"}
                src={this.state.imagen}
                alt="imagen404"
                style={{ width: "90%" }}
              />
            </div>
          </Col>
          <Col className="p-0">
            <div
              className="d-flex justify-content-center align-items-start bg-light flex-column"
              style={{ width: "100%", height: "100vh" }}
            >
              <h1 className="text-dark" style={{ fontSize: "125px" }}>
                404
              </h1>
              <h2 className="text-dark" style={{ fontSize: "50px" }}>
                Página no encontrada
              </h2>
              <p className="text-secondary">
                {
                  "No podemos encontrar la página que estás buscando. Podés regresar a la página anterior, ir a la página principal o contactar a soporte."
                }
              </p>
            </div>
          </Col>
        </>
      );
    }
  }
}

import React, { useState } from "react";
import { PropTypes } from "prop-types";

import Collapse from "react-bootstrap/Collapse";
import ListGroup from "react-bootstrap/ListGroup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./mapReferenceList.css";

import { getColor } from "./utils";
import MapReferenceCard from "./MapReferenceCard";

export default function MapReferenceList(props) {
  const [open, setOpen] = useState(true);

  const title = props.zona.nombre;
  const items = props.items.map((item, index) => (
    <ListGroup.Item className="d-flex px-2" key={item.Tapir.id}>
      <MapReferenceCard data={item} />
    </ListGroup.Item>
  ));

  return (
    <>
      <button
        action
        className="w-100 py-2 bg-white list-group-title"
        style={{ borderColor: getColor(props.zona.id) }}
        onClick={() => setOpen(!open)}
      >
        <div>
          <h6 className="text-left ">
            <FontAwesomeIcon
              className="mr-1"
              icon="vector-square"
              color={getColor(props.zona.id)}
            />
            {title.toUpperCase()}
          </h6>
        </div>
      </button>
      <Collapse in={open}>
        <ListGroup className="list-group-flush">{items}</ListGroup>
      </Collapse>
    </>
  );
}

MapReferenceList.propTypes = {
  /* Info para el título de la lista */
  zona: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nombre: PropTypes.string,
  }),

  /* Los ítems de la lista */
  items: PropTypes.array,
};

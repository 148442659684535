import React from "react";
import { Button, Col, Container, Modal } from "react-bootstrap";
import DatosPerfilForm from "./perfil/datosPerfil/DatosPerfilForm";
import useSubmit from "./commons/useSubmit";
import { api_root } from "./utils"; // Temporalmente

/** Este componente renderiza el layout
 * de la página que permite crearse una cuenta.
 */
export default function RegisterPage(props) {
  const [requestStatus, onSubmit] = useSubmit({
    urlSubmit: `${api_root}register/`,
    thenFunction: () => {},
  });

  let message;

  switch (requestStatus) {
    case 200:
      message =
        "Cuenta creada con éxito. Espere a ser habilitado para ingresar a la app.";
      return (
        <Modal.Dialog>
          <Modal.Header
            closeButton
            onClose={() => (window.location.href = "/login")}
          >
            <Modal.Title>ÉXITO</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => (window.location.href = "/login")}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      );
    case 400:
      message = "El usuario ingresado ya existe.";
      break;
    case 500:
      message = "Problemas en la red, pruebe mas tarde.";
      break;
    case "failed":
      message = "Problemas en la red, comuniquese con el admin.";
      break;
    default:
      message = null;
      break;
  }

  return (
    <Container align="center" className="my-3">
      <Col
        md={6}
        className="justify-content-center"
        style={{
          borderStyle: "solid",
          borderWidth: "2px",
          borderColor: "grey",
        }}
      >
        <div className="d-flex justify-content-center my-3">
          <h3>CREA TU CUENTA</h3>
        </div>

        <DatosPerfilForm onSubmit={onSubmit} />

        <p className={"text-danger"}>{message}</p>
      </Col>
    </Container>
  );
}

import React from "react";
import { PropTypes } from "prop-types";

import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryThreeQuarters,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faDotCircle } from "@fortawesome/free-regular-svg-icons";

import { getColorIconos } from "./utils";
import useDataProveedor from "./commons/useDataProveedor";
import { formatDate } from "./Alarmas";

export default function CardMediciones(props) {
  /** Se traen las ultimas mediciones para el tapir */
  const [mediciones, refreshMediciones] = useDataProveedor(
    `Mediciones/?tapir=${props.data.Tapir.id}&fechaFin=latest`
  );

  let bateria, posicion;
  if (mediciones.data) {
    bateria = mediciones.data.find((element) => element.TipoMedicion === 1);
    posicion = mediciones.data.find((element) => element.TipoMedicion === 2);
  }

  function print_fecha(fecha) {
    const aux = formatDate(fecha);
    return `Ult. med. el ${aux.substring(0, 10)} a las ${aux.substring(
      11
    )} hs.`;
  }

  function print_coordenadas(coordenadas) {
    const aux = JSON.parse(coordenadas);
    return `(${aux.lat}, ${aux.lng})`;
  }

  return (
    <Card className="w-100">
      <Card.Header className="pl-1 bg-white">
        <div>
          <FontAwesomeIcon
            className="mr-1"
            icon={faDotCircle}
            color={getColorIconos(props.data.Tapir.id)}
          />
          {props.data.Tapir.Nombre}
        </div>
      </Card.Header>
      <ListGroup className="list-group-flush ">
        <ListGroup.Item
          className="px-2 py-1"
          style={{ backgroundColor: "rgba(0,0,0,.03)" }}
        >
          <div>
            <FontAwesomeIcon
              icon={faBatteryThreeQuarters}
              rotation={270}
              size="xs"
              color="teal"
            />
            {bateria ? bateria.Valor + "% " + bateria.Collar.Nombre : "-- "}
          </div>
          <div style={{ fontSize: "0.7rem" }} className="pl-1">
            {bateria ? print_fecha(bateria.Fecha) : "--"}
          </div>
        </ListGroup.Item>
        <ListGroup.Item
          style={{ paddingLeft: "1.60rem", backgroundColor: "rgba(0,0,0,.03)" }}
          className="px-2 py-1"
        >
          <div>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              className="mx-1"
              size="xs"
              color="firebrick"
            />
            {posicion ? print_coordenadas(posicion.Valor) : "-- "}
          </div>
          <div style={{ fontSize: "0.7rem" }} className="pl-1">
            {posicion ? print_fecha(posicion.Fecha) : "--"}
          </div>
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
}

CardMediciones.propTypes = {
  /* El tapir del que se mostrará info */
  data: PropTypes.object,
};
